import { RouteUrls } from "../constants/routes";
import SignIn from "../views/SignIn";
import React, { Children, Component } from "react";

import ErrorBoundary from "../utils/ErrorHandler";

import LandingPageLayOut from "../views/LandingPageLayOut/LandingPageLayOut";
import LandingPage from "../views/LandingPage/LandingPage";
import Register from "../components/Landing-page-components/Register/Register";
import AdminPanelLayOut from "../views/adminPanelLayOut/AdminPanelLayOut";
import { Navigate } from "react-router-dom";
import EmployeeManagement from "../pages/EmployeeManagement/EmployeeManagement";
import { EmployeeManagementLayOut } from "../views/EmployeeManagementLayOut/EmployeeManagementLayOut";
import AddStaff from "../pages/EmployeeManagement/AddStaff/AddStaff";
import UserInfo from "../pages/EmployeeManagement/UserInfo/UserInfo";
import SettingBody from "../setting/settingBody/SettingBody";
import CompanyDetails from "../setting/Company/CompanyDetails/CompanyDetails";
import SettingLayOut from "../views/settingLayOut/SettingLayOut";
import Branches from "../setting/Company/CompanyBranches/Branches";
// import Branches from "../setting/Company/CompanyBranches/B";
import CompanyDepartments from "../setting/Company/CompanyDepartments/CompanyDepartments";
import Admins from "../setting/MyTeam/Admins/Admins";
import EmployeesManagers from "../setting/MyTeam/EmployeesManagers/EmployeesManagers";
import Shifts from "../setting/AttendanceSettings/Shifts/Shifts";
import Holiday from "../setting/AttendanceSettings/Holiday/Holiday";
import Dashboard from "../pages/Dashboard/Dashboard";
import Designation from "../setting/Company/CompanyDesignation/Designation";
import Roles from "../setting/Company/CompanyRoals/Roles";
import ErrorPage from "../views/Error/ErrorPage";
import CompanyReports from "../setting/Company/CompanyReports/CompanyReports";
import SuperAdminLayOut from "../views/superadminlayout/SuperAdminLayOut";
import UserManagement from "../pages/superAdmin/userManagement/UserManagement";
import SalaryDetails from "../setting/Salary/SalaryDetails";
import SalaryTemplate from "../setting/Salary/SalaryTemplate";
import SuperAdminSettings from "../setting/SuperAdmin/index";
import SuperAdminDashboard from "../pages/superAdmin/dashboard/index";
import LiveAttendance from "../pages/Attendance/LiveAttendance";
import DailyAttendance from "../pages/Attendance/DailyAttendance";
import EmployeeAttendance from "../pages/Attendance/EmployeeAttendance";
import MyEmployeeId from "../setting/Company/EmployeeId/EmployeeId";
import Tracking from "../pages/Tracking/Index";
import Package from "../pages/Package/Index";
import EmployeeManager from "../setting/Company/EmployeeManager/Index";
import Breaks from "../setting/AttendanceSettings/Breaks/Breaks";
import PerformanceManagement from "../pages/Performance/Index";
import TrainingDevelopment from "../pages/TrainingDevelopment/Index";
import Onboarding from "../onboarding/employee/Index";
import ResetPassword from "../onboarding/resetPassword/Index";
import MoreSettings from "../setting/MoreSettings/MoreSettings";
import ShiftSetting from "../setting/MoreSettings/ShiftSettings";
import Notification from "../pages/Notification/Index";
import MultiAttendance from "../pages/MultiAttendance/Index";
import EditAttendance from "../setting/MoreSettings/EditAttendanceSetting";
import UpdateAttendance from "../setting/MoreSettings/UpdateAttendance";
import LeaveSettings from "../setting/LeaveSettings/LeaveSettings";

import { useSelector } from "react-redux";
import Payroll from "../pages/Payroll/Index";
import { components } from "react-select";

const Routes = () => {
  // const { dashboardUrl, signInUrl } = RouteUrls;
  const { superAdmin } = useSelector((state) => state.auth);

  const publicRoutes = [
    {
      path: "/",
      Component: <LandingPageLayOut />,
      children: [
        { path: "", element: <LandingPage /> },
        { path: "login", element: <Register /> },
      ],
    },
  ];

  const privateRoutes = [
    {
      path: "",
      Component: superAdmin ? (
        <Navigate to={"/super-admin-panel"} />
      ) : (
        <ErrorBoundary>
          <AdminPanelLayOut />
        </ErrorBoundary>
      ),
      children: [
        { path: "", Component: <Navigate to={"dashboard"} /> },
        { path: "/dashboard", Component: <Dashboard /> },
        { path: "/reports", Component: <CompanyReports /> },
        { path: "/live-attendance", Component: <LiveAttendance /> },
        { path: "/daily-attendance", Component: <DailyAttendance /> },
        {
          path: "/live-attendance/employee-attendance",
          Component: <EmployeeAttendance />,
        },
        {
          path: "/daily-attendance/employee-attendance",
          Component: <EmployeeAttendance />,
        },
        { path: "/tracking", Component: <Tracking /> },
        {
          path: "/performance-management",
          Component: <PerformanceManagement />,
        },
        { path: "/payroll", Component: <Payroll /> },
        { path: "/raining-list", Component: <TrainingDevelopment /> },

        {
          path: "empmanagement",
          Component: <EmployeeManagementLayOut />,
          children: [
            { path: "", Component: <EmployeeManagement /> },
            { path: "add-staff", Component: <AddStaff /> },
            { path: "user-info/:id", Component: <UserInfo /> },
          ],
        },

        {
          path: "/notification",
          Component: <Notification />,
        },

        {
          path: "setting",
          Component: (
            <SettingLayOut>
              <SettingBody />
            </SettingLayOut>
          ),
        },

        { path: "company-details", Component: <CompanyDetails /> },
        { path: "company_branches", Component: <Branches /> },
        { path: "company-designation", Component: <Designation /> },
        { path: "company-Roles", Component: <Roles /> },
        { path: "company-reports", Component: <CompanyReports /> },
        { path: "my-employee-id", Component: <MyEmployeeId /> },
        { path: "company-departments", Component: <CompanyDepartments /> },
        { path: "myteam-admins", Component: <Admins /> },
        { path: "employee-manager", Component: <EmployeeManager /> },
        { path: "breaks", Component: <Breaks /> },

        {
          path: "myteam-Employees$Managers",
          Component: <EmployeesManagers />,
        },
        {
          path: "attendance-settings-shifts",
          Component: <Shifts />,
        },
        {
          path: "attendance-setting",
          Component: <MultiAttendance />,
        },
        {
          path: "attendance-settings-holiday",
          Component: <Holiday />,
        },
        {
          path: "more-attendance-settings",
          Component: <MoreSettings />,
        },

        {
          path: "update-module/:id",
          Component: <UpdateAttendance />,
        },

        {
          path: "shift-attendance-settings",
          Component: <ShiftSetting />,
        },
        {
          path: "leave-attendance",
          Component: <LeaveSettings />,
        },

        {
          path: "salary-details",
          Component: <SalaryDetails />,
        },
        {
          path: "salary-template",
          Component: <SalaryTemplate />,
        },
      ],
    },
    // Super admin path start from here
    {
      path: "super-admin-panel",
      Component: superAdmin ? (
        <ErrorBoundary>
          {" "}
          <SuperAdminLayOut />{" "}
        </ErrorBoundary>
      ) : (
        <Navigate to={""} />
      ),
      children: [
        { path: "", Component: <Navigate to={"dashboard"} /> },
        { path: "dashboard", Component: <SuperAdminDashboard /> },
        { path: "user-management", Component: <UserManagement /> },
        { path: "package", Component: <Package /> },
        {
          path: "setting",
          Component: (
            <SettingLayOut>
              <SuperAdminSettings />
            </SettingLayOut>
          ),
          // children: [{ path: "", Component: <h1>Hello</h1> }],
        },
      ],
    },

    { path: "*", Component: <ErrorPage /> },
  ];

  const onboardingRoute = [
    {
      path: "/employee/onboarding/:id",
      Component: (
        <ErrorBoundary>
          <Onboarding />
        </ErrorBoundary>
      ),
    },
    {
      path: "/resetpassword/:id",
      Component: (
        <ErrorBoundary>
          <ResetPassword />
        </ErrorBoundary>
      ),
    },
  ];

  return { privateRoutes, publicRoutes, onboardingRoute };
};

export default Routes;
