import { Col, Form, Row } from "react-bootstrap";
import "./Notification.css";
import React, { useState } from "react";

const Notification = () => {
  const user = localStorage.getItem("user_data")
    ? JSON.parse(localStorage.getItem("user_data"))
    : [];

  const [description, setDescription] = useState([
    {
      id: 1,
      title: "Here will title",
      description: "Here will be the description shown.",
    },
    {
      id: 2,
      title: "Here will title",
      description: "Here will be the description shown.",
    },
    {
      id: 3,
      title: "Here will title",
      description: "Here will be the description shown.",
    },
    {
      id: 4,
      title: "Here will title",
      description: "Here will be the description shown.",
    },
    {
      id: 5,
      title: "Here will title",
      description: "Here will be the description shown.",
    },
    {
      id: 6,
      title: "Here will title",
      description: "Here will be the description shown.",
    },
    {
      id: 7,
      title: "Here will title",
      description: "Here will be the description shown.",
    },
    {
      id: 8,
      title: "Here will title",
      description: "Here will be the description shown.",
    },
    {
      id: 9,
      title: "Here will title",
      description: "Here will be the description shown.",
    },
    {
      id: 10,
      title: "Here will title",
      description: "Here will be the description shown.",
    },
    {
      id: 11,
      title: "Here will title",
      description: "Here will be the description shown.",
    },
    {
      id: 12,
      title: "Here will title",
      description: "Here will be the description shown.",
    },
  ]);

  const approveNotification = (id) => {
    console.log(id);
  };
  const rejectNotification = (id) => {
    console.log(id);
  };
  return (
    <>
      <div className="SettingBody">
        <div className="SettingBody-head">
          {/* <Link to={"/setting/"}>
            <ArrowBackIcon sx={{ scale: 1 }} />
          </Link> */}
          <p>Notification</p>
        </div>

        <div className="SettingBody-card mt-3">
          {/* Button area */}
          <div className="notification_header">
            <Form>
              <Row>
                <Col sm="3">
                  <Form.Group>
                    <Form.Control placeholder="Search staff by name" />
                  </Form.Group>
                </Col>

                <Col sm="3">
                  <Form.Group>
                    <Form.Select>
                      <option value={""}>Branch</option>
                    </Form.Select>
                  </Form.Group>
                </Col>

                {/* <Col sm="3">
                  <Form.Group>
                    <Form.Select>
                      <option value={""}>Department</option>
                    </Form.Select>
                  </Form.Group>
                </Col>

                <Col sm="3">
                  <Form.Group>
                    <Form.Control type="date" />
                  </Form.Group>
                </Col> */}
              </Row>
            </Form>
          </div>

          <div >
            {/* Button area */}

            <div className="Branches-body" style={{ borderRadius: "7px", marginTop:"90px" }}>
              <Row>
                {description.map((row, idx) => {
                  return (
                    <Col sm="6" key={idx} className="mb-3">
                      <div className="card-container">
                        <h4 className="card-title">{row.title}</h4>
                        <p className="card-description">{row.description}</p>
                        <div className="card-actions">
                          <button
                            className="approve-btn"
                            onClick={() => approveNotification(row.id)}
                          >
                            Approve
                          </button>
                          <button
                            className="reject-btn"
                            onClick={() => rejectNotification(row.id)}
                          >
                            Reject
                          </button>
                        </div>
                      </div>
                    </Col>
                  );
                })}
              </Row>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default React.memo(Notification);
