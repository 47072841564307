import "./MultiAttendance.css";
import React from "react";
import { useCallback, useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
// icons
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { AgGridReact } from "ag-grid-react";
import AddIcon from "@mui/icons-material/Add";
import { CiEdit } from "react-icons/ci";
import { MdOutlineAssignmentTurnedIn } from "react-icons/md";
import { MdOutlineDeleteSweep } from "react-icons/md";
import Button from "react-bootstrap/Button";
import ButtonCom from "../../components/button/button";
import { ToastContainer, toast } from "react-toastify";
import Modal from "react-bootstrap/Modal";

import { useDispatch, useSelector } from "react-redux";
import {
  commonGetData,
  commonGetDataFourth,
  commonSaveData,
  initCommonState,
} from "../../redux/slices/commonSlice";
import axios from "axios";
import {
  getAttendModule,
  initMoreSettState,
} from "../../redux/slices/moreSettings";

const MultiAttendance = () => {
  const user = localStorage.getItem("user_data")
    ? JSON.parse(localStorage.getItem("user_data"))
    : [];

  const dispatch = useDispatch();
  const store = useSelector((state) => state.moreSettings);
  const commonStore = useSelector((state) => state.common);

  const [moduleList, setModuleList] = useState([]);

  const getModule = useCallback(() => {
    const path = `admin/getAttendanceModule?userId=${user.user_id}`;
    dispatch(getAttendModule(path));
  }, []);

  useEffect(() => {
    getModule();
  }, []);

  useEffect(() => {
    if (store.mSetGetAttModSuccess) {
      setModuleList(store.mSetGetAttModData);
    }
  }, [store.mSetGetAttModSuccess]);

  

  const [scroll] = useState("paper");

  // Edit function starts here --------------------------------
  const handelEdit = (data) => {
    console.log(data.data.attendanceId);
  };
  // Delete function starts here --------------------------------
  const [show, setShow] = useState(false);
  const handleModalOpen = () => setShow(true);
  const handleModalClose = () => setShow(false);
  const [deleteId, setDeleteId] = useState(null);
  const handleDelete = (id) => {
    setDeleteId(id);
    handleModalOpen();
  };
  const handleDeleteSubmit = () => {
    let data = {
      path: "admin/deleteAttendanceModule",
      data: {
        userId: user.user_id,
        attendanceId: deleteId,
      },
    };
    dispatch(commonSaveData(data));
    handleModalClose();
  };

  useEffect(() => {
    if (commonStore.commonSuccess) {
      toast.success(commonStore.commonMessage);
      getModule();
      handleModalClose1()
    }
    if (commonStore.commonError) {
      toast.error(commonStore.commonMessage);
    }
  }, [commonStore.commonSuccess, commonStore.commonError]);

  const [attendance_id, setAttendance_id] = useState("");

  // User list Variable and functions
  const [employeeList, setEmployeeList] = useState([]);
  const [filterEmployeeList, setFilterEmployeeList] = useState([]);

  const getEmployeeList = useCallback((id) => {
    const path = `admin/getStaffAttendanceList?userId=${user.user_id}&attendanceId=${id}`;
    dispatch(commonGetDataFourth(path));
  }, []);

  useEffect(() => {
    if (commonStore.commonGetFourthSuccess) {
      setEmployeeList(commonStore.commonGetFourthData);
      setFilterEmployeeList(commonStore.commonGetFourthData);
      dispatch(initCommonState());
    }
  }, [commonStore.commonGetFourthSuccess]);

  const handleAssignId = (id) => {
    setAttendance_id(id);
    getEmployeeList(id);
  };

  const [show1, setShow1] = useState(false);

  const handleModalClose1 = () => {
    setShow1(false);
    setSelectedUsers([]);
  };
  const handleShow1 = () => setShow1(true);

  const [selectedUsers, setSelectedUsers] = useState([]);
  const [searchQuery, setSearchQuery] = useState(""); // State for search query

  // Handle individual checkbox change
  const selectUser = (event, userId) => {
    const isChecked = event.target.checked;
    setSelectedUsers(
      (prevSelected) =>
        isChecked
          ? [...prevSelected, userId] // Add user to the list
          : prevSelected.filter((id) => id !== userId) // Remove user from the list
    );
  };

  // Handle "Select All" checkbox change
  const toggleSelectAll = (event) => {
    const isChecked = event.target.checked;
    if (isChecked) {
      const allUserIds = employeeList.map((row) => row.staffId); // Get all user IDs
      setSelectedUsers(allUserIds); // Select all users
    } else {
      setSelectedUsers([]); // Deselect all users
    }
  };

  // Determine if "Select All" should be checked
  const isSelectAll =
    selectedUsers.length === employeeList.length && employeeList.length > 0;

  // Filter employees based on the search query
  const searchEmployees = (val) => {
    setSearchQuery(val);
    const filteredEmployees = filterEmployeeList.filter((v) =>
      v.full_name.toLowerCase().includes(val.toLowerCase())
    );
    setEmployeeList(filteredEmployees);
  };

  const handleAssignShiftSubmit = (e) => {
    e.preventDefault();
    const data = {
      path: "admin/assignEmployeeAttendance",
      data: { 
        userId: user.user_id,
        staffId: selectedUsers.toString(),
        attendanceId: attendance_id,
      },
    };
    dispatch(commonSaveData(data));
  };

  const heading = [
    {
      headerName: "Module Name",
      field: "moduleName",
      flex: 1,
    },

    {
      headerName: "Action",
      field: "id",
      width: 200,
      cellRenderer: (rowData) => {
        return (
          <>
            <div className="Branches-table-button">
              <MdOutlineAssignmentTurnedIn
                style={{
                  cursor: "pointer",
                  fontSize: 25,
                  color: "#58d68d",
                  marginRight: "10px",
                }}
                onClick={() => {
                  handleShow1();
                  handleAssignId(rowData.data.attendanceId);
                }}
              />
              <Link to={`/update-module/${rowData.data.attendanceId}`}>
                <CiEdit
                  style={{ cursor: "pointer", fontSize: 30, color: "blue" }}
                  onClick={() => {
                    handelEdit(rowData);
                  }}
                />
              </Link>

              <MdOutlineDeleteSweep
                style={{ cursor: "pointer", fontSize: 30, color: "red" }}
                onClick={() => handleDelete(rowData.data.attendanceId)}
              />
            </div>
          </>
        );
      },
    },
  ];

  //===============================
  const paginationPageSize = 10;
  const paginationPageSizeSelector = [10, 20, 50, 100];

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        className="toast-custom"
      />

      {/* Modal for assign userI */}
      <Modal show={show1} onHide={handleModalClose1}>
        <Modal.Header closeButton>
          <Modal.Title>Assign Employee to this Shift</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ display: "flex", flexDirection: "column" }}>
          <input
            className="search-employee"
            type="text"
            placeholder="Search employees..."
            value={searchQuery}
            onChange={(e) => searchEmployees(e.target.value)}
          />

          <table>
            <thead>
              <tr>
                <th>
                  <input
                    type="checkbox"
                    checked={isSelectAll}
                    onChange={toggleSelectAll}
                    id="sel-all"
                    className="colored-checkbox"
                  />
                  &nbsp; &nbsp;
                  <label style={{ cursor: "pointer" }} htmlFor="sel-all">
                    Select All
                  </label>
                </th>
                <th>Employee Name</th>
              </tr>
            </thead>
            <tbody>
              {employeeList.length > 0 ? (
                employeeList.map((row) => (
                  <tr key={row.staffId}>
                    <td>
                      {/* {
                      row.attendanceAssignedStatus == "1" ? <input
                        type="checkbox"
                        checked={selectedUsers.includes(row.staffId)}
                        onChange={(e) => selectUser(e, row.staffId)}
                        id={"shift" + row.staffId}
                      />
                    } */}
                      <input
                        type="checkbox"
                        checked={
                          row.attendanceAssignedStatus == "1" ||
                          selectedUsers.includes(row.staffId)
                        }
                        onChange={(e) => selectUser(e, row.staffId)}
                        id={"shift" + row.staffId}
                        className="colored-checkbox"
                      />
                    </td>
                    <td>
                      <label
                        style={{ cursor: "pointer" }}
                        htmlFor={"shift" + row.staffId}
                      >
                        {" "}
                        {row.full_name}{" "}
                      </label>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="2">No employees found</td>
                </tr>
              )}
            </tbody>
          </table>
        </Modal.Body>
        <Modal.Footer>
          <Button
            style={{
              backgroundColor: "#566573",
              color: "#fff",
              marginRight: "5px",
            }}
            onClick={handleModalClose1}
          >
            Cancel
          </Button>
          <Button
            style={{ backgroundColor: "#5dade2", color: "#fff" }}
            onClick={handleAssignShiftSubmit}
          >
            Submit
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={show} onHide={handleModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>Delete Module</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure! to delete this record?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleModalClose}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleDeleteSubmit}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>

      <div className="SettingBody">
        <div className="SettingBody-head">
          <Link to={"/setting/"}>
            <ArrowBackIcon sx={{ scale: 1 }} />
          </Link>
          <p>Company Branches</p>
        </div>

        <div className="SettingBody-card mt-3">
          <div className="Branches-body" style={{ borderRadius: "7px" }}>
            <div className="Branches-btn">
              <Link to="/more-attendance-settings">
                <ButtonCom
                  type="button"
                  class_name="p_btn"
                  text={"Create Attendance Module"}
                  url_or_onclick={null}
                  icon={<AddIcon />}
                  width={""}
                />
              </Link>
            </div>
            <div
              className="ag-theme-quartz" // applying the Data Grid theme
              style={{ height: 350, width: "100%" }}
            >
              <AgGridReact
                rowData={moduleList}
                columnDefs={heading}
                omLayout="autoHeight"
                pagination={true}
                paginationPageSize={paginationPageSize}
                paginationPageSizeSelector={paginationPageSizeSelector}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default React.memo(MultiAttendance);
