import "./UserManagement.css";
import { useCallback, useEffect, useState } from "react";

import PDFreader from "./PDFreader";

// icons
// import { IoEyeOutline } from "react-icons/io5";
import { EyeIcon, Circle } from "../../../components/icons/Icons";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Accordion from "react-bootstrap/Accordion";
import Switch from "@mui/material/Switch";
import { ToastContainer, toast } from "react-toastify";
import DataTable from "react-data-table-component";
import {
  convertPDFToBase64,
  getFileExtension,
} from "../../../services/commonServices";
import { useDispatch, useSelector } from "react-redux";
import { saveUser } from "../../../redux/slices/userSlice";
import {
  commonGetData,
  commonGetDataOne,
  commonGetDataTwo,
  initCommonState,
} from "../../../redux/slices/commonSlice";
import ButtonCom from "../../../components/button/button";
import { colors } from "@mui/material";

const UserManagement = () => {
  const dispatch = useDispatch();
  const comState = useSelector((state) => state.common);
  const [open, setOpen] = useState(false);

  const [adminUserList, setAdminUserList] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [filteredData, setFilteredData] = useState(adminUserList);

  const getAdminList = useCallback(() => {
    dispatch(commonGetDataTwo("superior/getAdminUserList"));
  }, []);

  useEffect(() => {
    getAdminList();
  }, []);

  useEffect(() => {
    if (comState.commonGetTwoSuccess) {
      setAdminUserList(comState.commonGetTwoData);
      setFilteredData(comState.commonGetTwoData);
      dispatch(initCommonState());
    }
  }, [comState.commonGetTwoSuccess]);

  const handleInputSearch = (event) => {
    const value = event.target.value || "";
    setSearchText(value);
  };
  const handleSearch = (event) => {
    const value = searchText;

    if (value && typeof value === "string") {
      const lowerCaseValue = value.toLowerCase();

      const filtered = adminUserList.filter((item) =>
        item.companyName.toLowerCase().includes(lowerCaseValue)
      );
      setFilteredData(filtered);
    }
  };

  const [isView, setIsView] = useState(false);
  const [viewData, setViewData] = useState({});
  const handleClickOpen = () => {
    setIsView(false);
    setViewData({});
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const viewDetails = (row) => {
    setViewData(row);
    setIsView(true);
    setOpen(true);
  };

  const columns = [
    {
      name: "Company Name",
      selector: (row) => row.companyName,
    },
    {
      name: "Company Address",
      selector: (row) => row.companyAddress,
    },
    {
      name: "Company Mobile",
      selector: (row) => row.companyMobile,
    },
    {
      name: "Company Email",
      selector: (row) => row.companyEmail,
    },
    {
      name: "Company GST",
      selector: (row) => row.companyGST,
    },
    {
      name: "Employee Count",
      selector: (row) => row.employeeCount,
    },
    {
      name: "Status",
      selector: (row) => (row.status == 1 ? "Active" : "Inactive"),
    },
    {
      name: "Action",
      selector: (row) => {
        return <EyeIcon class_name="" onClick={() => viewDetails(row)} />;
      },
    },
  ];

  const customStyles = {
    table: {
      style: {
        textAlign: "center", // Center-align header text
        justifyContent: "center", // Additional property to center content horizontally
        display: "flex", // Border around the entire table
      },
    },
    rows: {
      style: {
        textAlign: "center", // Center-align header text
        justifyContent: "center", // Additional property to center content horizontally
        display: "flex",
      },
    },
    cells: {
      style: {
        textAlign: "center", // Center-align header text
        justifyContent: "center", // Additional property to center content horizontally
        display: "flex",
      },
    },
    header: {
      style: {
        textAlign: "center", // Center-align header text
        justifyContent: "center", // Additional property to center content horizontally
        display: "flex",
      },
    },
    headCells: {
      style: {
        textAlign: "center", // Center-align header text
        justifyContent: "center", // Additional property to center content horizontally
        display: "flex",
        borderRight: "1px solid lightgray",
      },
    },
  };

  return (
    <>
      <div className="user_management">
        {/* <Heading
          heading={"User Management"}
          buttonName={"Add User"}
          onClick={handleClickOpen}
        /> */}

        <div className="page-top-bar">
          <h4>User Management</h4>
          <ButtonCom
            type="button"
            class_name={"p_btn"}
            text="Add User"
            url_or_onclick={handleClickOpen}
            icon={null}
            width={""}
          />
        </div>

        <div className="user_management-table">
          <div className="user_management-table-search">
            <input
              placeholder="Search"
              value={searchText}
              onChange={handleInputSearch}
              style={{ borderRadius: "7px", border: "1px solid gray" }}
            />
            <ButtonCom
              type="button"
              class_name={"p_btn"}
              text="Search"
              url_or_onclick={handleSearch}
              icon={null}
              width={""}
            />
          </div>

          <DataTable
            columns={columns}
            data={filteredData}
            pagination
            subHeader
            customStyles={customStyles}
          />
        </div>
      </div>

      {/* {popTap && (
        <div className="pop-from"> */}
      <CompanyDepartmentsForm
        handleClose={handleClose}
        open={open}
        isView={isView}
        viewData={viewData}
      />
      {/* </div> */}
      {/* )} */}
    </>
  );
};

const CompanyDepartmentsForm = ({ handleClose, open, isView, viewData }) => {
  const dispatch = useDispatch();
  const commonState = useSelector((state) => state.common);
  const userState = useSelector((state) => state.user);

  const [businessType, setBusinessType] = useState([]);
  const [modules, setModules] = useState([]);

  const getModuleList = useCallback(() => {
    dispatch(commonGetData("superior/getModuleList"));
    dispatch(commonGetDataOne("admin/getBusinessTypeList"));
  }, []);

  useEffect(() => {
    getModuleList();
  }, []);

  useEffect(() => {
    if (commonState.commonGetSuccess) {
      setModules(commonState.commonGetData);
    }
    if (commonState.commonGetOneSuccess) {
      setBusinessType(commonState.commonGetOneData);
    }
  }, [commonState.commonGetSuccess, commonState.commonGetOneSuccess]);

  const [checked, setChecked] = useState(false);

  const [addStaff, setAddStaff] = useState({
    companyName: "",
    companyAddress: "",
    companyPhone: "",
    companyEmail: "",
    companyGst: "",
    companyIndustryType: 1,
    fullName: "",
    designation: "",
    email: "",
    mobile: "",
    employeeCount: "",
    accessModules: "",
    companyIncDocs: "",
    companyGstDocs: "",
    companyEmployeeId: "",
    companyLogo: "",
  });

  const [validated, setValidated] = useState(false);

  const checkLengthHandle = (e) => {
    const { name, value } = e.target;
    if (!isNaN(value) && value.length <= 10) {
      setAddStaff({ ...addStaff, [name]: value });
    }
  };

  const inputFields = [
    {
      title: "Company Information:",
      child: [
        {
          name: "companyName",
          label: "Company Name",
          placeholder: "Enter Company Name",
          type: "text",
          required: true,
        },
        {
          name: "companyAddress",
          label: "Company Address",
          placeholder: "Enter Company Address",
          type: "text",
          required: true,
        },
        {
          name: "companyPhone",
          label: "Phone Number",
          placeholder: "Enter Phone Number",
          type: "text",
          required: true,
        },
        {
          name: "companyEmail",
          label: "Email Address (Username/Login Address)",
          placeholder: "Enter Email Address",
          type: "email",
          required: true,
        },
        {
          name: "companyGst",
          label: "Tax Identification Number",
          placeholder: "Enter Tax Identification Number (e.g., GSTIN in India)",
          type: "text",
          required: true,
        },

        {
          name: "companyIndustryType",
          label: "Industry Type",
          placeholder: "Enter Industry Type",
          type: "dropdown",
          child: businessType,
          required: true,
        },
      ],
    },
    {
      title: "Primary Point of Contact:",
      child: [
        {
          name: "fullName",
          label: "User Name",
          placeholder: "Enter User Name",
          type: "text",
          required: true,
        },
        {
          name: "designation",
          label: "Designation",
          placeholder: "Enter Designation",
          type: "text",
          required: true,
        },
        {
          name: "email",
          label: "Email ID",
          placeholder: "Enter Email ID",
          type: "text",
          required: true,
        },
        {
          name: "mobile",
          label: "Phone Number",
          placeholder: "Enter Phone Number",
          type: "text",
          required: true,
        },
      ],
    },
    {
      title: "Employee Count:",
      child: [
        {
          name: "employeeCount",
          label: "Number of Employees",
          placeholder: "Enter Number of Employees",
          type: "text",
          required: true,
        },
      ],
    },
    {
      title: "Preferred HRMS Configuration:",
      child: [
        {
          name: "accessModules",
          label: "Modules Required",
          placeholder: "Enter Modules Required",
          type: "checkbox",
          child: modules,
          required: true,
        },
      ],
    },
    {
      title: "Company Registration Documents:",
      child: [
        {
          name: "companyIncDocs",
          label: "Incorporation Certificate",
          placeholder: "Select File",
          type: "file",
          required: false,
        },
        {
          name: "companyGstDocs",
          label: "Tax Documents",
          placeholder: "Select File",
          type: "file",
          required: true,
        },
        {
          name: "companyEmployeeId",
          label: "Employee IDs",
          placeholder: "Enter Employee IDs",
          type: "text",
          required: false,
        },
      ],
    },
  ];

  const [saveModules, setSaveModules] = useState([]);

  const handleChange = (e) => {
    const {
      target: { name, value },
    } = e;
    setAddStaff({ ...addStaff, [name]: value });
  };

  const handleModuleChange = (event) => {
    let elm = document.querySelectorAll(".modules-check");
    if (event.target.checked) {
      const pD = [];
      elm.forEach((r) => {
        r.childNodes[0].checked = event.target.checked;
        pD.push(parseInt(r.childNodes[0].value));
      });
      setSaveModules(pD);
    } else {
      elm.forEach((r) => {
        r.childNodes[0].checked = event.target.checked;
      });
      setSaveModules([]);
    }
    setChecked(event.target.checked);
  };

  function deleteElement(arr, element) {
    const index = arr.indexOf(element);

    if (index !== -1) {
      arr.splice(index, 1);
    }

    return arr;
  }

  const handleModuleChangeInd = (e) => {
    const checked = e.target.checked;
    const value = e.target.value;
    if (checked) {
      const preMod = saveModules;
      preMod.push(parseInt(value));
      setSaveModules(preMod);
    } else {
      const x = deleteElement(saveModules, parseInt(value));
      setSaveModules(x);
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];

    convertPDFToBase64(file)
      .then((base64String) => {
        const { name } = e.target;
        setAddStaff({ ...addStaff, [name]: base64String });
      })
      .catch((error) => {
        console.error("Error converting PDF to Base64:", error);
      });
  };

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();
    if (form.checkValidity() === false) {
      setValidated(true);
    } else {
      let d = {
        companyName: addStaff.companyName,
        companyAddress: addStaff.companyAddress,
        companyPhone: addStaff.companyPhone,
        companyEmail: addStaff.companyEmail,
        companyGst: addStaff.companyGst,
        companyIndustryType: addStaff.companyIndustryType,
        fullName: addStaff.fullName,
        designation: addStaff.designation,
        email: addStaff.email,
        mobile: addStaff.mobile,
        employeeCount: addStaff.employeeCount,
        accessModules: saveModules.toString(),
        companyIncDocs: addStaff.companyIncDocs,
        companyGstDocs: addStaff.companyGstDocs,
        companyEmployeeId: addStaff.companyEmployeeId,
        companyLogo: addStaff.companyLogo,
      };
      dispatch(saveUser(d));
    }
  };

  useEffect(() => {
    if (userState.uSuccess) {
      toast.success("User added successfully ");
      handleClose();
    }
    if (userState.uError) {
      toast.error(userState.uMessage);
      handleClose();
    }
  }, [userState.uSuccess, userState.uError]);

  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  // Event handler when PDF is loaded
  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  // adminUserList
  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        className="toast-custom"
      />
      <Dialog
        fullWidth={true}
        maxWidth={isView ? "md" : "sm"}
        open={open}
        onClose={handleClose}
      >
        <DialogTitle
          className="pb-2 mb-4"
          style={{
            borderBottom: "1px solid lightgray",
            fontSize: "16px",
            fontWeight: "bold",
          }}
        >
          {isView ? "View Details" : "Add User"}
        </DialogTitle>

        <DialogContent>
          {!isView && (
            <Form
              noValidate
              validated={validated}
              onSubmit={handleSubmit}
              encType="multipart/form-data"
            >
              {inputFields.map((row, idx) => {
                return (
                  <div key={idx}>
                    <h3 className="mb-3">{row.title}</h3>
                    {row.child.map((row1, idx1) => {
                      return (
                        <Row className="mb-3" key={idx1}>
                          <Col sm="12">
                            <Form.Label>
                              {row1.label}{" "}
                              {row1.required && (
                                <span style={{ color: "#cd6155" }}>*</span>
                              )}{" "}
                            </Form.Label>
                            {row1.type == "text" || row1.type == "email" ? (
                              <Form.Control
                                type={row1.type}
                                name={row1.name}
                                value={addStaff[row1.name]}
                                placeholder={row1.placeholder}
                                onChange={
                                  row1.name == "companyPhone" ||
                                  row1.name == "mobile"
                                    ? checkLengthHandle
                                    : handleChange
                                }
                                // onKeyDown={row1.name == "companyPhone" || row1.name == "mobile" ? handleKeyPress : () => {}}
                                required={row1.required}
                                key={idx1}
                              />
                            ) : null}

                            {row1.type == "dropdown" ? (
                              <Form.Select
                                type={row1.type}
                                name={row1.name}
                                placeholder={row1.placeholder}
                                onChange={handleChange}
                                required={row1.required}
                                key={idx1}
                              >
                                <option value="">Select Industry Type</option>
                                {row1.child.map((r, i) => {
                                  return (
                                    <option value={r.businessTypeId} key={i}>
                                      {r.type_name}
                                    </option>
                                  );
                                })}
                              </Form.Select>
                            ) : null}

                            {row1.type == "file" ? (
                              <Form.Control
                                type={row1.type}
                                name={row1.name}
                                placeholder={row1.placeholder}
                                onChange={handleFileChange}
                                required={row1.required}
                                key={idx1}
                              />
                            ) : null}

                            {row1.type == "checkbox" ? (
                              <>
                                <br />
                                <Switch
                                  checked={checked}
                                  onChange={handleModuleChange}
                                  inputProps={{ "aria-label": "controlled" }}
                                  className="mb-3"
                                />

                                <div
                                  style={{
                                    display: "flex",
                                    flexWrap: "wrap",
                                    gap: "10px",
                                  }}
                                >
                                  {row1.child.map((row2, idx2) => {
                                    return (
                                      <Form.Check
                                        name="checkAll"
                                        label={row2.module_name}
                                        value={row2.id}
                                        onChange={handleModuleChangeInd}
                                        className="modules-check"
                                        key={idx2}
                                      />
                                    );
                                  })}
                                </div>
                              </>
                            ) : null}
                          </Col>
                        </Row>
                      );
                    })}
                  </div>
                );
              })}

              <DialogActions
                className="pt-3"
                style={{ borderTop: "1px solid lightgray" }}
              >
                <Button
                  variant="contained"
                  color="default"
                  onClick={handleClose}
                >
                  Cancel
                </Button>
                <Button variant="contained" color="info" type="submit">
                  Save
                </Button>
              </DialogActions>
            </Form>
          )}
          {isView && (
            <Accordion defaultActiveKey="0">
              <Accordion.Item eventKey="0">
                <Accordion.Header>
                  <div
                    style={{
                      backgroundColor: "#ebf5fb",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    Company Information:
                  </div>
                </Accordion.Header>
                <Accordion.Body>
                  <table>
                    <tr>
                      <td>Company Name :</td>
                      <td style={{ fontWeight: "300" }}>
                        {viewData.companyName}
                      </td>
                    </tr>
                    <tr>
                      <td>Company Email :</td>
                      <td style={{ fontWeight: "300" }}>
                        {viewData.companyEmail}
                      </td>
                    </tr>
                    <tr>
                      <td>Company Mobile :</td>
                      <td style={{ fontWeight: "300" }}>
                        {viewData.companyMobile}
                      </td>
                    </tr>
                    <tr>
                      <td>Company GST :</td>
                      <td style={{ fontWeight: "300" }}>
                        {viewData.companyGST}
                      </td>
                    </tr>
                    <tr>
                      <td>Company Industry :</td>
                      <td style={{ fontWeight: "300" }}>
                        {viewData.companyIndustry}
                      </td>
                    </tr>
                    <tr>
                      <td>Company Address :</td>
                      <td style={{ fontWeight: "300" }}>
                        {viewData.companyAddress}
                      </td>
                    </tr>
                  </table>
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="1">
                <Accordion.Header>Primary Point of Contact:</Accordion.Header>
                <Accordion.Body>
                  <table>
                    <tr>
                      <td>Name :</td>
                      <td style={{ fontWeight: "300" }}>{viewData.fullName}</td>
                    </tr>
                    <tr>
                      <td>Email :</td>
                      <td style={{ fontWeight: "300" }}>{viewData.email}</td>
                    </tr>
                    <tr>
                      <td>Mobile :</td>
                      <td style={{ fontWeight: "300" }}>{viewData.mobile}</td>
                    </tr>
                    <tr>
                      {" "}
                      <td>Designation</td> <td>{viewData.designation}</td>{" "}
                    </tr>
                  </table>
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="2">
                <Accordion.Header>Employee Count:</Accordion.Header>
                <Accordion.Body>
                  <table>
                    <tr>
                      <td>Count :</td>
                      <td style={{ fontWeight: "300" }}>
                        {viewData.employeeCount}
                      </td>
                    </tr>
                  </table>
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="3">
                <Accordion.Header>
                  Preferred HRMS Configuration:
                </Accordion.Header>
                <Accordion.Body>
                  <div
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      alignItems: "center",
                      justifyContent: "start",
                      gap: "15px",
                    }}
                  >
                    {viewData.modulesList.map((r, i) => {
                      return (
                        <span className="module-lis-view">
                          <Circle /> {r}
                        </span>
                      );
                    })}
                  </div>
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="4">
                <Accordion.Header>
                  GST Documents:
                </Accordion.Header>
                <Accordion.Body>
                  <PDFreader
                    file={
                      viewData.gstDocName
                    }
                  />
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="5">
                <Accordion.Header>Incorporation Documents: </Accordion.Header>
                <Accordion.Body>
                  {viewData.incDocName !== "" ? (
                    getFileExtension(viewData.incDocName) == ".pdf" ? (
                      <PDFreader file={viewData.incDocName} />
                    ) : (
                      <img
                        src={viewData.incDocName}
                        alt="image-file"
                        width={"100%"}
                      />
                    )
                  ) : (
                    "No Document"
                  )}
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default UserManagement;
