import "./style.css";
import * as React from "react";
import { useEffect, useState, useCallback } from "react";
import { useParams } from "react-router-dom";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import DatePicker from "react-datepicker";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
// icons
import { FaCirclePlus } from "react-icons/fa6";
import BadgeIcon from "@mui/icons-material/Badge";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import FamilyRestroomIcon from "@mui/icons-material/FamilyRestroom";
import SaveAsIcon from "@mui/icons-material/SaveAs";
import AttachmentIcon from "@mui/icons-material/Attachment";
import { PencilIcon } from "../../components/icons/Icons";
// images
import ProfilePic from "../../assets/Images/user-avatar.jpg";
import { Button, Form, Row, Col } from "react-bootstrap";

// Redux
import { useDispatch, useSelector } from "react-redux";
import {
  commonSaveData,
  initCommonState,
} from "../../redux/slices/commonSlice";
// import { fetchDepartmentData } from "../../redux/slices/departmentSlice";
// import {
//   fetchBranchData,
//   initBranchState,
// } from "../../redux/slices/branchSlice";
import { fetchDesignationList } from "../../redux/slices/designationSlice";
import {
  noAuthGet,
  noAuthGet1,
  noAuthGet2,
  noAuthGet3,
  noAuthGet4,
  noAuthGet5,
  noAuthGet6,
  noAuthGet7,
} from "../../redux/slices/noAuthSlice";
// Function
import {
  inputsTwo,
  addressInfos,
  addressInfo2,
  emergencyContact,
} from "../function";
import { convertPDFToBase64 } from "../../services/commonServices";

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 35,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        "linear-gradient( 95deg,#25A3F8 0%,#25A3F8 50%,#25A3F8 100%)",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        "linear-gradient( 95deg, #dc7633 0%,#dc7633 50%, #dc7633 100%)",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 5,
    border: 0,
    backgroundColor: "#eaeaf0",
    borderRadius: 1,
    ...theme.applyStyles("dark", {
      backgroundColor: theme.palette.grey[800],
    }),
  },
}));

const ColorlibStepIconRoot = styled("div")(({ theme }) => ({
  backgroundColor: "#ccc",
  zIndex: 1,
  color: "#fff",
  width: 70,
  height: 70,
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  ...theme.applyStyles("dark", {
    backgroundColor: theme.palette.grey[700],
  }),
  variants: [
    {
      props: ({ ownerState }) => ownerState.active,
      style: {
        backgroundImage:
          "linear-gradient( 136deg, #25A3F8 0%, #25A3F8 50%, #25A3F8 100%)",
        boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
      },
    },
    {
      props: ({ ownerState }) => ownerState.completed,
      style: {
        backgroundImage:
          "linear-gradient( 136deg, #dc7633 0%, #dc7633 50%, #dc7633 100%)",
      },
    },
  ],
}));

function ColorlibStepIcon(props) {
  const { active, completed, className } = props;

  const icons = {
    1: <BadgeIcon style={{ fontSize: "30px" }} />,
    2: <AssignmentIndIcon style={{ fontSize: "30px" }} />,
    3: <AccountBalanceIcon style={{ fontSize: "30px" }} />,
    4: <FamilyRestroomIcon style={{ fontSize: "30px" }} />,
    5: <SaveAsIcon style={{ fontSize: "30px" }} />,
    6: <AttachmentIcon style={{ fontSize: "30px" }} />,
  };

  return (
    <ColorlibStepIconRoot
      ownerState={{ completed, active }}
      className={className}
    >
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
}

ColorlibStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
  /**
   * The label displayed in the step icon.
   */
  icon: PropTypes.node,
};

const steps = [
  "Personal Information",
  "Previous Employment",
  "Accounts & Statutory",
  "Family Details",
  "Nomination Details",
];

// Component starts from here ========================================
const Onboarding = () => {
  // store ===========================================================
  const dispatch = useDispatch();
  const { id } = useParams();

  const state = useSelector((state) => state.noAuth);

  const commonState = useSelector((state) => state.common);
  // const branchState = useSelector((state) => state.branch);
  // const departState = useSelector((state) => state.department);
  // const designationState = useSelector((state) => state.designation);

  // const [designationList, setDesignationList] = useState([]);

  const getAllList = useCallback(() => {
    dispatch(fetchDesignationList());
    dispatch(noAuthGet(`employee/getEmployeeRecords?staffId=${id}`));
    dispatch(noAuthGet1("getBloodGroup"));
    dispatch(noAuthGet2("getQualifications"));
    dispatch(noAuthGet3("getMaritalStatus"));
    dispatch(noAuthGet4("getGender"));
    dispatch(noAuthGet5("getStates"));
    dispatch(noAuthGet6("getCountry"));
    dispatch(noAuthGet7("getRelationship"));
  });

  useEffect(() => {
    getAllList();
  }, []);
  const [userId, setUserId] = useState("");
  const [userRecord, setUserRecord] = useState([]);
  const [bloodGroup, setBloodGroup] = useState([]);
  const [qualification, setQualification] = useState([]);
  const [maritalStatus, setMaritalStatus] = useState([]);
  const [gender, setGender] = useState([]);
  const [states, setStates] = useState([]);
  const [country, setCountry] = useState([]);
  const [relationship, setRelationship] = useState([]);

  useEffect(() => {
    if (state.noAuthGetSuccess) {
      setUserRecord(state.noAuthGetData);
      if (state.noAuthGetData.length > 0) {
        setUserId(state.noAuthGetData[0].userId);
        localStorage.setItem("userId", state.noAuthGetData[0]?.userId);
      }
    }

    if (state.noAuthGet1Success) {
      const newArr = state.noAuthGet1Data.map((v) => {
        return { label: v.blood_group_name, value: v.bloodGroupId };
      });
      setBloodGroup(newArr);
    }

    if (state.noAuthGet2Success) {
      const newArr = state.noAuthGet2Data.map((v) => {
        return { label: v.qualificationName, value: v.qualificationId };
      });
      setQualification(newArr);
    }

    if (state.noAuthGet3Success) {
      const newArr = state.noAuthGet3Data.map((v) => {
        return { label: v.maritalName, value: v.maritalId };
      });
      setMaritalStatus(newArr);
    }

    if (state.noAuthGet4Success) {
      const newArr = state.noAuthGet4Data.map((v) => {
        return { label: v.genderName, value: v.genderId };
      });
      setGender(newArr);
    }

    if (state.noAuthGet5Success) {
      const newArr = state.noAuthGet5Data.map((v) => {
        return { label: v.stateName, value: v.stateId };
      });
      setStates(newArr);
    }

    if (state.noAuthGet6Success) {
      const newArr = state.noAuthGet6Data.map((v) => {
        return { label: v.countryName, value: v.countryId };
      });
      setCountry(newArr);
    }

    if (state.noAuthGet7Success) {
      const newArr = state.noAuthGet7Data.map((v) => {
        return { label: v.relationshipName, value: v.relationshipId };
      });
      setRelationship(newArr);
    }
  }, [
    state.noAuthGetSuccess,
    state.noAuthGet1Success,
    state.noAuthGet2Success,
    state.noAuthGet3Success,
    state.noAuthGet4Success,
    state.noAuthGet5Success,
    state.noAuthGet6Success,
    state.noAuthGet7Success,

    state.noAuthGetError,
    state.noAuthGet1Error,
    state.noAuthGet2Error,
    state.noAuthGet3Error,
    state.noAuthGet4Error,
    state.noAuthGet5Error,
    state.noAuthGet6Error,
    state.noAuthGet7Error,
  ]);

  // variables
  const [validated, setValidated] = useState(false);
  const [isEmptyField, setIsEmptyField] = useState(false);
  const [updateStaff, setUpdateStaff] = useState({
    userId: localStorage.getItem("userId"),
    staffId: id,
    bloodGroup: "",
    personalEmail: "",
    selfieImage: "",
    ProfilePic: ProfilePic,
    fatherName: "",
    maritalStatus: "",
    marriageDate: "",
    spouseName: "",
    nationality: "",
    gender: "",
    dateOfBirth: "",

    address_line_1: "",
    address_line_2: "",
    addressType: "",
    addessText: "",
    city: "",
    state: "",
    pincode: "",
    country: "1",

    address_line_11: "",
    address_line_22: "",
    addressType1: "",
    addessText1: "",
    city1: "",
    state1: "",
    pincode1: "",
    country1: "1",

    emergencyContactName: "",
    emergencyContactNumber: "",
    emergencyContactRelation: "",
    educationList: [
      {
        educationNumber: "",
        qualification: "",
        fromYear: "",
        toYear: "",
        institute: "",
        qualificationDocs: "",
      },
    ],
    employementList: [
      {
        employementNumber: "",
        companyName: "",
        designation: "",
        fromDate: "",
        toDate: "",
        companyAddress: "",
        offerLetterDocs: "",
        payslipDocs: "",
      },
    ],
    bankAccountType: "",
    bankAccountNumber: "",
    bankAccountNumber1: "",
    bankBranch: "",
    bankIfsc: "",
    bankName: "",
    pfPreAccountNumber: "",
    pfAccountNumber: "",
    uanNumber: "",
    panNumber: "",
    panName: "",
    panDocs: "",
    aadharNumber: "",
    aadharName: "",
    aadharFrontDocs: "",
    aadharBackDocs: "",
    familyMemberList: [
      {
        familyMember: "",
        familyMembername: "",
        relationship: "",
        dob: "",
        gender: "",
        bloodGroup: "",
        nationality: "",
        minorMajor: "",
      },
    ],

    nominationList: [
      {
        nominationType: 1,
        nomineeNumber: "",
        nomineeName: "",
        nominationPercent: "",
      },
    ],

    epf_nomination: [
      {
        nominationType: "1",
        nomineeNumber: "1",
        nomineeName: "",
        nominationPercent: "",
      },
    ],
    eps_nomination: "",
    esi_nomination: "",
    gratuity_nomination: [
      {
        nominationType: "2",
        nomineeNumber: "1",
        nomineeName: "",
        nominationPercent: "",
      },
    ],

    qualification: [
      {
        educationNumber: "1",
        qualification: "",
        fromYear: "",
        toYear: "",
        institute: "",
        qualificationDocs: "",
      },
    ],
    isFresher: false
  });

  const handleIsFresher = (event) => {
    const {target: {name, checked}} = event
    setUpdateStaff({
    ...updateStaff,
    isFresher: checked
    })
  }

  const fileHandle = (e) => {
    const file = e.target.files[0];
    const { name } = e.target;
    const reader = new FileReader();

    reader.readAsDataURL(file);

    reader.onload = function (event) {
      const base64 = event.target.result;
      setUpdateStaff({
        ...updateStaff,
        [name]: base64,
      });
      if (name == "selfieImage") {
        setUpdateStaff({
          ...updateStaff,
          ProfilePic: base64,
        });
      }
    };

    setIsEmptyField(false);
  };

  const handelChange = (e) => {
    const { name, value } = e.target;
    setUpdateStaff((pv) => ({ ...pv, [name]: value }));
    setIsEmptyField(false);
  };

  const handleCompanyPdf = (idx, e) => {
    const file = e.target.files[0];
    const { name } = e.target;
    const list = [...updateStaff.employementList];

    convertPDFToBase64(file).then((res) => {
      list[idx][name] = res;
      setUpdateStaff({
        ...updateStaff,
        employementList: list,
      });
    });
    setIsEmptyField(false);
  };

  // Family member ======================================
  const addMoreFamilyMember = (e) => {
    const list = [...updateStaff.familyMemberList];
    list.push({
      familyMember: "",
      familyMembername: "",
      relationship: "",
      dob: "",
      gender: "",
      bloodGroup: "",
      nationality: "",
      minorMajor: "1",
    });
    setUpdateStaff({
      ...updateStaff,
      familyMemberList: list,
    });
  };
  const removeFamilyMember = (index) => {
    const list = [...updateStaff.familyMemberList];
    list.splice(index, 1);
    setUpdateStaff({
      ...updateStaff,
      familyMemberList: list,
    });
  };
  const handleFamilyMember = (index, e) => {
    const { name, value } = e.target;
    const list = [...updateStaff.familyMemberList];
    list[index][name] = value;
    setUpdateStaff({
      ...updateStaff,
      familyMemberList: list,
    });
    setIsEmptyField(false);
  };
  // FAmily member ======================================

  // Gratuity Nomination ======================================
  const addGratuityNomination = (e) => {
    const list = [...updateStaff.gratuity_nomination];
    list.push({
      nominationType: "2",
      nomineeNumber: "1",
      nomineeName: "",
      nominationPercent: "",
    });
    setUpdateStaff({
      ...updateStaff,
      gratuity_nomination: list,
    });
  };
  const removeGratuityNomination = (index) => {
    const list = [...updateStaff.gratuity_nomination];
    list.splice(index, 1);
    setUpdateStaff({
      ...updateStaff,
      gratuity_nomination: list,
    });
  };
  const handleGratuityNomination = (index, e) => {
    const { name, value } = e.target;
    const list = [...updateStaff.gratuity_nomination];
    list[index][name] = value;
    setUpdateStaff({
      ...updateStaff,
      gratuity_nomination: list,
    });
    setIsEmptyField(false);
  };
  // Gratuity Nomination ======================================

  // EPF Nomination ====================================
  const addMoreNomination = (e) => {
    const list = [...updateStaff.epf_nomination];
    list.push({
      nominationType: "1",
      nomineeNumber: "1",
      nomineeName: "",
      nominationPercent: "",
    });
    setUpdateStaff({
      ...updateStaff,
      epf_nomination: list,
    });
  };
  const removeNomination = (index) => {
    const list = [...updateStaff.epf_nomination];
    list.splice(index, 1);
    setUpdateStaff({
      ...updateStaff,
      epf_nomination: list,
    });
  };
  const handleNomination = (index, e) => {
    const { name, value } = e.target;
    const list = [...updateStaff.epf_nomination];
    list[index][name] = value;
    setUpdateStaff({
      ...updateStaff,
      epf_nomination: list,
    });
    setIsEmptyField(false);
  };
  // EPF Nomination ====================================

  // Qualification =====================================
  const addQualification = (e) => {
    const list = [...updateStaff.qualification];
    list.push({
      educationNumber: "1",
      qualification: "",
      fromYear: "",
      toYear: "",
      institute: "",
      qualificationDocs: "",
    });
    setUpdateStaff({
      ...updateStaff,
      qualification: list,
    });
  };
  const removeQualification = (index) => {
    const list = [...updateStaff.qualification];
    list.splice(index, 1);
    setUpdateStaff({
      ...updateStaff,
      qualification: list,
    });
  };
  const handleQualification = (index, e) => {
    console.log(e);
    const { name, value } = e.target;
    const list = [...updateStaff.qualification];
    list[index][name] = value;
    setUpdateStaff({
      ...updateStaff,
      qualification: list,
    });
    setIsEmptyField(false);
  };

  const handleQualificationFromYear = (index, date) => {
    if (date) {
      const name = "fromYear";
      const list = [...updateStaff.qualification];
      const year = date.getFullYear();
      list[index][name] = year;
      setUpdateStaff({
        ...updateStaff,
        qualification: list,
      });
      setIsEmptyField(false);
    }
  };

  const handleQualificationToYear = (index, date) => {
    if (date) {
      const name = "toYear";
      const list = [...updateStaff.qualification];
      const year = date.getFullYear();
      list[index][name] = year;
      setUpdateStaff({
        ...updateStaff,
        qualification: list,
      });
      setIsEmptyField(false);
    }
  };

  const handleQualificationFile = (idx, e) => {
    const file = e.target.files[0];
    const { name } = e.target;

    const list = [...updateStaff.qualification];

    convertPDFToBase64(file).then((res) => {
      list[idx][name] = res;
      setUpdateStaff({
        ...updateStaff,
        qualification: list,
      });
    });
    setIsEmptyField(false);
  };
  // Qualification =====================================

  // Company =====================================
  const addMoreCompany = () => {
    const list = [...updateStaff.employementList];
    list.push({
      employementNumber: "",
      companyName: "",
      designation: "",
      fromDate: "",
      toDate: "",
      companyAddress: "",
      offerLetterDocs: "",
      payslipDocs: "",
    });
    setUpdateStaff({
      ...updateStaff,
      employementList: list,
    });
  };

  const removeCompany = (index) => {
    const list = [...updateStaff.employementList];
    list.splice(index, 1);
    setUpdateStaff({
      ...updateStaff,
      employementList: list,
    });
  };

  const handleCompany = (index, e) => {
    const { name, value } = e.target;
    const list = [...updateStaff.employementList];
    list[index][name] = value;
    setUpdateStaff({
      ...updateStaff,
      employementList: list,
    });
    setIsEmptyField(false);
  };
  // Company =====================================

  // File Selection ============================
  const selectFile = () => {
    document.getElementById("fileInput").click();
  };
  const [attachmentFileName, setAttachmentFileName] = useState("");
  const attachmentFile = (event) => {
    const file = event.target.files[0];
    setAttachmentFileName(file.name);
  };

  // Stepper variables and functions ===========================
  const [activeStep, setActiveStep] = React.useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const sameAddress = (e) => {
    const {
      target: { checked },
    } = e;
    if (checked) {
      setUpdateStaff({
        ...updateStaff,
        address_line_11: updateStaff.address_line_1,
        address_line_22: updateStaff.address_line_2,
        city1: updateStaff.city,
        state1: updateStaff.state,
        pincode1: updateStaff.pincode,
        country1: updateStaff.country,
      });
    } else {
      setUpdateStaff({
        ...updateStaff,
        address_line_11: "",
        address_line_22: "",
        city1: "",
        state1: "",
        pincode1: "",
        country1: "",
      });
    }
  };

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();
    if (form.checkValidity() === false) {
      setValidated(true);
      setIsEmptyField(true);
    } else {
      let preData = {
        userId: localStorage.getItem("userId"),
        staffId: updateStaff.staffId,
        bloodGroup: updateStaff.bloodGroup,
        personalEmail: updateStaff.personalEmail,
        selfieImage: updateStaff.selfieImage,
        fatherName: updateStaff.fatherName,
        maritalStatus: updateStaff.maritalStatus,
        marriageDate: updateStaff.marriageDate,
        spouseName: updateStaff.spouseName,
        nationality: updateStaff.nationality,
        gender: updateStaff.gender,
        dateOfBirth: updateStaff.dateOfBirth,

        addressList: [
          {
            addressType: 1,
            addessText:
              updateStaff.address_line_1 + " " + updateStaff.address_line_2,
            city: updateStaff.city,
            state: updateStaff.state,
            pincode: updateStaff.pincode,
            country: updateStaff.country,
            emergencyContactName: updateStaff.emergencyContactName,
            emergencyContactNumber: updateStaff.emergencyContactNumber,
            emergencyContactRelation: updateStaff.emergencyContactRelation,
          },
          {
            addressType: 2,
            addessText:
              updateStaff.address_line_11 + " " + updateStaff.address_line_22,
            city: updateStaff.city1,
            state: updateStaff.state1,
            pincode: updateStaff.pincode1,
            country: updateStaff.country1,
            emergencyContactName: "",
            emergencyContactNumber: "",
            emergencyContactRelation: "",
          },
        ],
        educationList: updateStaff.qualification,
        employementList: updateStaff.employementList,
        bankAccountType: updateStaff.bankAccountType,
        bankAccountNumber: updateStaff.bankAccountNumber,
        bankAccountNumber1: updateStaff.bankAccountNumber1,
        bankBranch: updateStaff.bankBranch,
        bankIfsc: updateStaff.bankIfsc,
        bankName: updateStaff.bankName,
        pfPreAccountNumber: updateStaff.pfPreAccountNumber,
        pfAccountNumber: updateStaff.pfAccountNumber,
        uanNumber: updateStaff.uanNumber,
        panNumber: updateStaff.panNumber,
        panName: updateStaff.panName,
        panDocs: updateStaff.panDocs,
        aadharNumber: updateStaff.aadharNumber,
        aadharName: updateStaff.aadharName,
        aadharFrontDocs: updateStaff.aadharFrontDocs,
        aadharBackDocs: updateStaff.aadharBackDocs,
        familyMemberList: updateStaff.familyMemberList,
        nominationList: updateStaff.epf_nomination.concat(
          updateStaff.gratuity_nomination
        ),
        epf_nomination: updateStaff.epf_nomination,
        eps_nomination: updateStaff.eps_nomination,
        esi_nomination: updateStaff.esi_nomination,
        gratuity_nomination: updateStaff.gratuity_nomination,
        qualification: updateStaff.qualification,
        isFresher: updateStaff.isFresher
      };
      let d = {
        path: "employee/saveEmployeeOnboard",
        data: preData,
      };
      dispatch(commonSaveData(d));
    }
  };

  useEffect(() => {
    if (commonState.commonSuccess) {
      toast.success(commonState.commonMessage);
      dispatch(initCommonState());
    }
    if (commonState.commonError) {
      toast.error(commonState.commonMessage);
    }
  }, [commonState.commonSuccess, commonState.commonError]);

  const [isView, setIsView] = useState(false);
  setTimeout(() => {
    setIsView(true);
  }, 3000);

  const handleSelfie = () => {
    document.getElementById("selfieImage").click();
  };


  const [data, setData] = useState(
    JSON.parse(localStorage.getItem("familyMemberList")) || []
  );

  const saveFourthStepData = (name) => {
    const data = updateStaff[name];
    setData(data)
    localStorage.setItem("familyMemberList", JSON.stringify(data));
  };

  useEffect(() => {
    // Listen for changes in localStorage from other tabs or windows
    const handleStorageChange = (event) => {
      if (event.key === "familyMemberList") {
        setData(event.newValue);
      }
    };
    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);


  return !isView ? (
    <h3 className="page_loader">Data is loading...</h3>
  ) : userId ? (
    <div className="stepper-container">
      <ToastContainer
        position="top-right"
        autoClose={2000}
        className="toast-custom"
      />
      <div className="stepper-header">
        <h1>Hi, {userRecord.length > 0 && userRecord[0].staffName}</h1>
        <p>Help us know you better! Provide all your employment Information.</p>
        <div className="small-description">
          <div className="profile">
            <img src={ProfilePic} alt="" />
            <div>
              <h1>{userRecord.length > 0 && userRecord[0].staffName}</h1>
              <span>
                Emp ID:- {userRecord.length > 0 && userRecord[0].empId}
              </span>
            </div>
          </div>
          {/* ----------------------- */}
          <div className="small-detail">
            <span>
              Mobile :- {userRecord.length > 0 && userRecord[0].mobile}
            </span>
            {/* <span>Department :- </span> */}
          </div>
          {/* ----------------------- */}
          {/* <div className="small-detail">
          <span>Location :- </span>
          <span>Designation :- </span>
        </div> */}
        </div>
      </div>
      <Stack sx={{ width: "100%" }} spacing={2} className="sticky-stepper">
        <Stepper
          alternativeLabel
          activeStep={activeStep}
          connector={<ColorlibConnector />}
        >
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel StepIconComponent={ColorlibStepIcon}>
                <div style={{ fontSize: "16px" }}>{label}</div>
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      </Stack>
      <Form
        noValidate
        validated={validated}
        onSubmit={handleSubmit}
        encType="multipart/form-data"
      >
        {/* STEP 1 =========================================== */}

        <div
          className="stepper-body page"
          style={{ display: activeStep == 0 ? "block" : "none" }}
        >
          <div className="selfie-image" style={{ position: "relative" }}>
            <input
              type="file"
              id="selfieImage"
              name="selfieImage"
              onChange={fileHandle}
              style={{ display: "none" }}
            />
           
            <div style={{ position: "relative" }}>
              <img src={updateStaff.ProfilePic} alt="" onClick={handleSelfie} />
              <PencilIcon
              class_name="selfie-edit-icon"
              onClick={() => {
                console.log("");
              }}
              color="skyblue"
              fontSize="30px"
              fontWeight="600"
            />
            </div>
          </div>
          <Row>
            <h1 className="heading">Personal Details</h1>
            {inputsTwo.map((item, i) => (
              <Col sm="4" className="mb-3" key={i}>
                <Form.Group>
                  <Form.Label>
                    {item.label}
                    <span style={{ color: "red" }}>
                      {item.required ? "*" : ""}
                    </span>
                  </Form.Label>
                  {item.chi ? (
                    <Form.Select
                      name={item.name}
                      onChange={handelChange}
                      required={item.required}
                    >
                      {item.name == "bloodGroup" && (
                        <option value="">Select blood group </option>
                      )}
                      {item.name == "gender" && (
                        <option value="">Select gender </option>
                      )}
                      {item.name == "maritalStatus" && (
                        <option value="">Select marital status </option>
                      )}

                      {item.name == "bloodGroup" &&
                        bloodGroup.map((dv, i) => (
                          <option
                            key={i}
                            value={dv.value}
                            selected={
                              dv.value == updateStaff[item.name]
                                ? "selected"
                                : ""
                            }
                          >
                            {dv.label}
                          </option>
                        ))}

                      {item.name == "gender" &&
                        gender.map((dv, i) => (
                          <option
                            key={i}
                            value={dv.value}
                            selected={
                              dv.value == updateStaff[item.name]
                                ? "selected"
                                : ""
                            }
                          >
                            {dv.label}
                          </option>
                        ))}

                      {item.name == "maritalStatus" &&
                        maritalStatus.map((dv, i) => (
                          <option
                            key={i}
                            value={dv.value}
                            selected={
                              dv.value == updateStaff[item.name]
                                ? "selected"
                                : ""
                            }
                          >
                            {dv.label}
                          </option>
                        ))}

                      {item.name == "nationality" &&
                        item.chi.map((dv, i) => (
                          <option
                            key={i}
                            value={dv.value}
                            selected={
                              dv.value == updateStaff[item.name]
                                ? "selected"
                                : ""
                            }
                          >
                            {dv.label}
                          </option>
                        ))}
                    </Form.Select>
                  ) : (
                    <Form.Control
                      name={item.name}
                      type={item.type || ""}
                      value={updateStaff[item.name]}
                      placeholder={item.placeholder}
                      required={item.required}
                      onChange={handelChange}
                    />
                  )}
                  <Form.Control.Feedback type="invalid">
                    Please provide valid {item.name}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            ))}

            {updateStaff.maritalStatus == "1" && (
              <>
                <Col sm="4" className="mb-3">
                  <Form.Label>Marriage Date</Form.Label>
                  <Form.Control
                    type="date"
                    name={"marriageDate"}
                    value={updateStaff.marriage_date}
                    placeholder={"Marriage Date"}
                    onChange={handelChange}
                    required={updateStaff.maritalStatus == 1 ? true : false}
                  />
                </Col>

                <Col sm="4" className="mb-3">
                  <Form.Label>Spouse Name</Form.Label>
                  <Form.Control
                    type="text"
                    name={"spouseName"}
                    value={updateStaff.spouseName}
                    placeholder={"Spouse Name"}
                    onChange={handelChange}
                    required={updateStaff.maritalStatus == 1 ? true : false}
                  />
                </Col>
              </>
            )}
          </Row>
          <Row>
            <h1 className="heading">Address Details</h1>
            <h4 className="mb-3">Permanent Address</h4>
            {addressInfos.map((item, i) => (
              <Col sm="4" className="mb-3">
                <Form.Group key={i}>
                  <Form.Label>
                    {item.label}
                    <span style={{ color: "red" }}>
                      {item.required ? "*" : ""}
                    </span>
                  </Form.Label>

                  {item.name == "state" || item.name == "country" ? (
                    <Form.Select
                      name={item.name}
                      value={updateStaff[item.name]}
                      placeholder={item.placeholder}
                      onChange={handelChange}
                      required={item.required}
                    >
                      {item.name == "state" &&
                        states.map((dv, i) => (
                          <option
                            key={i}
                            value={dv.value}
                            selected={
                              dv.value == updateStaff[item.name]
                                ? "selected"
                                : ""
                            }
                          >
                            {dv.label}
                          </option>
                        ))}

                      {item.name == "country" &&
                        country.map((dv, i) => (
                          <option
                            key={i}
                            value={dv.value}
                            selected={
                              dv.value == updateStaff[item.name]
                                ? "selected"
                                : ""
                            }
                          >
                            {dv.label}
                          </option>
                        ))}
                    </Form.Select>
                  ) : (
                    <Form.Control
                      name={item.name}
                      value={updateStaff[item.name]}
                      placeholder={item.placeholder}
                      onChange={handelChange}
                      required={item.required}
                    />
                  )}
                  <Form.Control.Feedback type="invalid">
                    Please provide valid {item.name}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            ))}
          </Row>
          <Row>
            <h4 className="mb-3 mt-2">Present Address</h4>
            {addressInfo2.map((item, i) => (
              <Col sm="4" className="mb-3">
                <Form.Group key={i}>
                  <Form.Label>
                    {item.label}
                    <span style={{ color: "red" }}>
                      {item.required ? "*" : ""}
                    </span>
                  </Form.Label>
                  {item.name == "state1" || item.name == "country1" ? (
                    <Form.Select
                      name={item.name}
                      value={updateStaff[item.name]}
                      placeholder={item.placeholder}
                      onChange={handelChange}
                      required={item.required}
                    >
                      {item.name == "state1" &&
                        states.map((dv, i) => (
                          <option
                            key={i}
                            value={dv.value}
                            selected={
                              dv.value == updateStaff[item.name]
                                ? "selected"
                                : ""
                            }
                          >
                            {dv.label}
                          </option>
                        ))}

                      {item.name == "country1" &&
                        country.map((dv, i) => (
                          <option
                            key={i}
                            value={dv.value}
                            selected={
                              dv.value == updateStaff[item.name]
                                ? "selected"
                                : ""
                            }
                          >
                            {dv.label}
                          </option>
                        ))}
                    </Form.Select>
                  ) : (
                    <Form.Control
                      name={item.name}
                      value={updateStaff[item.name]}
                      placeholder={item.placeholder}
                      onChange={handelChange}
                      required={item.required}
                    />
                  )}
                  <Form.Control.Feedback type="invalid">
                    {item.name == "address_line_11"
                      ? "Please provide valid address_line_1"
                      : null}
                    {item.name == "address_line_22"
                      ? "Please provide valid address_line_2"
                      : null}
                    {item.name == "city1" ? "Please provide valid city" : null}
                    {item.name == "state1"
                      ? "Please provide valid state"
                      : null}
                    {item.name == "country1"
                      ? "Please provide valid country"
                      : null}
                    {item.name == "pincode1"
                      ? "Please provide valid pincode"
                      : null}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            ))}
          </Row>
          <br />
          <input type="checkbox" onChange={sameAddress} /> Same as present
          address
          <Row className="mt-5">
            <h1 className="heading">Emergency Contact</h1>
            {emergencyContact.map((item, idx) => {
              return (
                <Col sm="4" className="mb-3" key={idx}>
                  <Form.Group>
                    <Form.Label>
                      {item.label}
                      <span style={{ color: "red" }}>
                        {item.required ? "*" : ""}
                      </span>
                    </Form.Label>
                    <Form.Control
                      name={item.name}
                      value={updateStaff[item.name]}
                      placeholder={item.placeholder}
                      onChange={handelChange}
                      required={item.required}
                    />
                  </Form.Group>
                  <Form.Control.Feedback type="invalid">
                    Please provide valid {item.name}
                  </Form.Control.Feedback>
                </Col>
              );
            })}
          </Row>
          {/* qualification ============================ removeQualification */}
          <Row>
            <h1 className="heading mt-3">Qualification</h1>
          </Row>
          {updateStaff.qualification.map((item, idx) => {
            return (
              <Row key={idx}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <h4 className="mb-3">Education-{parseInt(idx) + 1}</h4>
                  {idx !== 0 && (
                    <span
                      style={{ color: "red", cursor: "pointer" }}
                      onClick={() => {
                        removeQualification(idx);
                      }}
                    >
                      X
                    </span>
                  )}
                </div>

                <Col sm="4" className="mb-3">
                  <Form.Group>
                    <Form.Label>
                      {"Qualification"}
                      <span style={{ color: "red" }}>*</span>
                    </Form.Label>
                    <Form.Select
                      name={"qualification"}
                      value={updateStaff.qualification[idx].qualification}
                      onChange={(e) => {
                        handleQualification(idx, e);
                      }}
                      required
                    >
                      <option value={""}>Select Qualification</option>
                      {qualification.map((item, idx) => {
                        return <option value={item.value}>{item.label}</option>;
                      })}
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      Please provide valid Qualification
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>

                <Col sm="4" className="mb-3">
                  <Form.Group>
                    <Form.Label>
                      {"From Year"}
                      <span style={{ color: "red" }}>*</span>
                    </Form.Label>
                    {/* <Form.Control
                      type="year"
                      name={"fromYear"}
                      value={updateStaff.qualification[idx].fromYear}
                      onChange={(date) => {
                        handleQualificationFromYear(idx, date);
                      }}
                      required
                    /> */}
                    <DatePicker
                      name={"toYear"}
                      selected={updateStaff.qualification[idx].fromYear}
                      // onChange={(date) => setYear(date)}
                      onChange={(date) => {
                        handleQualificationFromYear(idx, date);
                      }}
                      showYearPicker
                      dateFormat="yyyy"
                      placeholderText="Select Year"
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      Please provide valid from year
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>

                <Col sm="4" className="mb-3">
                  <Form.Group>
                    <Form.Label>
                      {"To Year"}
                      <span style={{ color: "red" }}>*</span>
                    </Form.Label>
                    {/* <Form.Control
                      type="year"
                      name={"toYear"}
                      value={updateStaff.qualification[idx].toYear}
                      onChange={(e) => {
                        handleQualification(idx, e);
                      }}
                      required
                    /> */}
                    <DatePicker
                      name={"toYear"}
                      selected={updateStaff.qualification[idx].toYear}
                      // onChange={(date) => setYear(date)}
                      onChange={(date) => {
                        handleQualificationToYear(idx, date);
                      }}
                      showYearPicker
                      dateFormat="yyyy"
                      placeholderText="Select Year"
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      Please provide valid to year
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>

                <Col sm="4" className="mb-3">
                  <Form.Group>
                    <Form.Label>
                      {"Institute"}
                      <span style={{ color: "red" }}>*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name={"institute"}
                      placeholder={"Enter institute name"}
                      value={updateStaff.qualification[idx].institute}
                      onChange={(e) => {
                        handleQualification(idx, e);
                      }}
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      Please provide valid institute
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>

                <Col sm="4" className="mb-3">
                  <Form.Group>
                    <Form.Label>
                      {"Upload docs"}
                      <span style={{ color: "red" }}>*</span>
                    </Form.Label>
                    <Form.Control
                      type="file"
                      name={"qualificationDocs"}
                      onChange={(e) => {
                        handleQualificationFile(idx, e);
                      }}
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      Please provide valid docs
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
            );
          })}
          <div
            onClick={addQualification}
            style={{
              fontSize: "16px",
              color: "#2e86c1",
              cursor: "pointer",
            }}
          >
            <FaCirclePlus style={{ fontSize: "16px" }} /> Add more qualification
          </div>
          {isEmptyField && (
            <div className="mt-3" style={{ color: "red", textAlign: "right" }}>
              All mandatory fields are required.
            </div>
          )}
        </div>

        {/* STEP 2 ====================================================== */}
        <div
          className="stepper-body page"
          style={{ display: activeStep == 1 ? "block" : "none" }}
        >

            <Form.Group>
              <Form.Check onChange={handleIsFresher} label="Is Fresher" />
            </Form.Group>


          {!updateStaff.isFresher && updateStaff.employementList.map((row, idx) => {
            return (
              <Row>
                {idx != 0 && (
                  <div
                    onClick={() => {
                      removeCompany(idx);
                    }}
                    style={{
                      marginTop: "37px",
                      marginBottom: "20px",
                      paddingBottom: "5px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      borderBottom: "1px solid lightgray",
                    }}
                  >
                    <span>Employment-{parseInt(idx) + 1}</span>
                    <span
                      style={{
                        color: "red",
                        cursor: "pointer",
                      }}
                    >
                      X
                    </span>
                  </div>
                )}

                <Col sm="4">
                  <Form.Group className="mb-3">
                    <Form.Label>
                      Company Name <span className="error">*</span>
                    </Form.Label>
                    <Form.Control
                      required
                      type="text"
                      name="companyName"
                      value={updateStaff.employementList[idx].companyName}
                      placeholder="Enter company name"
                      onChange={(e) => {
                        handleCompany(idx, e);
                      }}
                    />
                    <Form.Control.Feedback type="invalid">
                      Please provide a valid company name.
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                {/* ============================= */}
                <Col sm="4">
                  <Form.Group className="mb-3">
                    <Form.Label>
                      Designation <span className="error">*</span>
                    </Form.Label>
                    <Form.Control
                      required
                      type="text"
                      name="designation"
                      value={updateStaff.employementList[idx].designation}
                      placeholder="Enter designation"
                      style={{ color: "gray" }}
                      onChange={(e) => {
                        handleCompany(idx, e);
                      }}
                    />
                    <Form.Control.Feedback type="invalid">
                      Please provide a valid designation.
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                {/* ============================= */}
                <Col sm="4">
                  <Form.Group className="mb-3">
                    <Form.Label>
                      From Date <span className="error">*</span>
                    </Form.Label>
                    <Form.Control
                      required
                      type="date"
                      name="fromDate"
                      value={updateStaff.employementList[idx].fromDate}
                      onChange={(e) => {
                        handleCompany(idx, e);
                      }}
                    />
                    <Form.Control.Feedback type="invalid">
                      Please provide a valid joining date.
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                {/* ============================= */}
                <Col sm="4">
                  <Form.Group className="mb-3">
                    <Form.Label>
                      To Date <span className="error">*</span>
                    </Form.Label>
                    <Form.Control
                      required
                      type="date"
                      name="toDate"
                      value={updateStaff.employementList[idx].toDate}
                      onChange={(e) => {
                        handleCompany(idx, e);
                      }}
                    />
                    <Form.Control.Feedback type="invalid">
                      Please provide a valid leaving date.
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>

                <Col sm="4">
                  <Form.Group className="mb-3">
                    <Form.Label>
                      Company Address
                      <span className="error">*</span>
                    </Form.Label>
                    <Form.Control
                      name="companyAddress"
                      value={updateStaff.employementList[idx].companyAddress}
                      placeholder="Enter Address"
                      onChange={(e) => {
                        handleCompany(idx, e);
                      }}
                    />
                    <Form.Control.Feedback type="invalid">
                      Please select a valid Company Address.
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col sm="4">
                  <Form.Group className="mb-3">
                    <Form.Label>
                      Offer Letter Docs
                      {/* <span className="error">*</span> */}
                    </Form.Label>
                    <Form.Control
                      type="file"
                      name="offerLetterDocs"
                      onChange={(e) => {
                        handleCompanyPdf(idx, e);
                      }}
                    />
                    <Form.Control.Feedback type="invalid">
                      Please provide a valid Offer Letter Docs.
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>

                {/* ============================= */}
                <Col sm="4">
                  <Form.Group className="mb-3">
                    <Form.Label>
                      Payslip Docs
                      {/* <span className="error">*</span> */}
                    </Form.Label>
                    <Form.Control
                      type="file"
                      name="payslipDocs"
                      onChange={(e) => {
                        handleCompanyPdf(idx, e);
                      }}
                    />
                    <Form.Control.Feedback type="invalid">
                      Please provide a payslip Docs.
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
            );
          })}
{
  !updateStaff.isFresher &&  <div>
            <span
              style={{ color: "#3498db", cursor: "pointer" }}
              onClick={addMoreCompany}
            >
              <FaCirclePlus /> Add more company
            </span>
          </div>
}
         

          {isEmptyField && (
            <div className="mt-3" style={{ color: "red", textAlign: "right" }}>
              All mandatory fields are required.
            </div>
          )}
        </div>

        {/* STEP 3 ============================================ */}
        <div
          className="stepper-body page"
          style={{ display: activeStep == 2 ? "block" : "none" }}
        >
          <Row className="mt-3">
            <h4
              className="mb-3"
              style={{
                borderBottom: "1px solid lightgray",
                paddingBottom: "5px",
                fontWeight: "500",
              }}
            >
              Bank Account Details
            </h4>
            <Col sm="4" className="mb-3">
              <Form.Group>
                <Form.Label>
                  Bank Account Number
                  <span style={{ color: "red" }}>*</span>
                </Form.Label>

                <Form.Control
                  name="bankAccountNumber"
                  value={updateStaff.bankAccountNumber}
                  placeholder="Enter account number"
                  onChange={handelChange}
                  width="200px"
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please enter valid bank account number
                </Form.Control.Feedback>
              </Form.Group>
            </Col>

            <Col sm="4" className="mb-3">
              <Form.Group>
                <Form.Label>
                  Confirm Bank Account Number
                  <span style={{ color: "red" }}>*</span>
                </Form.Label>

                <Form.Control
                  name="bankAccountNumber1"
                  value={updateStaff.bankAccountNumber1}
                  placeholder="Enter account number"
                  onChange={handelChange}
                  width="200px"
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please enter valid account number
                </Form.Control.Feedback>
              </Form.Group>
            </Col>

            <Col sm="4" className="mb-3">
              <Form.Group>
                <Form.Label>
                  IFSC Code
                  <span style={{ color: "red" }}>*</span>
                </Form.Label>

                <Form.Control
                  name="bankIfsc"
                  value={updateStaff.bankIfsc}
                  placeholder="Enter account ifsc code"
                  onChange={handelChange}
                  width="200px"
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please enter valid IFSC Code
                </Form.Control.Feedback>
              </Form.Group>
            </Col>

            <Col sm="4" className="mb-3">
              <Form.Group>
                <Form.Label>
                  Branch
                  <span style={{ color: "red" }}>*</span>
                </Form.Label>

                <Form.Control
                  name="bankBranch"
                  value={updateStaff.bankBranch}
                  placeholder="Enter branch"
                  onChange={handelChange}
                  width="200px"
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please enter valid branch
                </Form.Control.Feedback>
              </Form.Group>
            </Col>

            <Col sm="4" className="mb-3">
              <Form.Group>
                <Form.Label>
                  Name as per Bank
                  <span style={{ color: "red" }}>*</span>
                </Form.Label>

                <Form.Control
                  name="bankName"
                  value={updateStaff.bankName}
                  placeholder="Enter name as per bank"
                  onChange={handelChange}
                  width="200px"
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please enter valid name as per bank
                </Form.Control.Feedback>
              </Form.Group>
            </Col>

            <Col sm="4" className="mb-3">
              <Form.Group>
                <Form.Label>
                  Account Type
                  <span style={{ color: "red" }}>*</span>
                </Form.Label>

                <Form.Select
                  name="bankAccountType"
                  placeholder="Enter account type"
                  onChange={handelChange}
                  width="200px"
                  required
                >
                  <option value={""}>Select Account Type</option>
                  <option value={1}>Saving</option>
                  <option value={2}>Current</option>
                  <option value={3}>Joint</option>
                  <option value={4}>Others</option>
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  Please enter valid account type
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>

          <Row className="mt-3">
            <h4
              className="mb-3"
              style={{
                borderBottom: "1px solid lightgray",
                paddingBottom: "5px",
                fontWeight: "500",
              }}
            >
              PF Account Details
            </h4>
            <Col sm="4" className="mb-3">
              <Form.Group>
                <Form.Label>
                  Previous Account Number
                  {/* <span style={{ color: "red" }}>*</span> */}
                </Form.Label>

                <Form.Control
                  name="pfAccountNumber"
                  value={updateStaff.pfAccountNumber}
                  placeholder="Enter PF account number"
                  onChange={handelChange}
                  width="200px"
                />
                <Form.Control.Feedback type="invalid">
                  Please enter valid previous account number
                </Form.Control.Feedback>
              </Form.Group>
            </Col>

            <Col sm="4" className="mb-3">
              <Form.Group>
                <Form.Label>
                  UAN
                  {/* <span style={{ color: "red" }}>*</span> */}
                </Form.Label>

                <Form.Control
                  name="uanNumber"
                  value={updateStaff.uanNumber}
                  placeholder="Enter UAN account number"
                  onChange={handelChange}
                  width="200px"
                />
                <Form.Control.Feedback type="invalid">
                  Please enter valid UAN number
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>

          <Row className="mt-3">
            <h4
              className="mb-3"
              style={{
                borderBottom: "1px solid lightgray",
                paddingBottom: "5px",
                fontWeight: "500",
              }}
            >
              Permanent Account Number
            </h4>
            <Col sm="4" className="mb-3">
              <Form.Group>
                <Form.Label>
                  PAN Number
                  <span style={{ color: "red" }}>*</span>
                </Form.Label>

                <Form.Control
                  name="panNumber"
                  value={updateStaff.panNumber}
                  placeholder="Enter account number"
                  onChange={handelChange}
                  width="200px"
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please enter valid PAN Number
                </Form.Control.Feedback>
              </Form.Group>
            </Col>

            <Col sm="4" className="mb-3">
              <Form.Group>
                <Form.Label>
                  Name in PAN
                  <span style={{ color: "red" }}>*</span>
                </Form.Label>

                <Form.Control
                  name="panName"
                  value={updateStaff.panName}
                  placeholder="Enter account number"
                  onChange={handelChange}
                  width="200px"
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please enter valid Name in PAN
                </Form.Control.Feedback>
              </Form.Group>
            </Col>

            <Col sm="4" className="mb-3">
              <Form.Group>
                <Form.Label>
                  Upload PAN
                  <span style={{ color: "red" }}>*</span>
                </Form.Label>

                <Form.Control
                  type="file"
                  name="panDocs"
                  placeholder="Enter account number"
                  onChange={fileHandle}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please enter valid Upload PAN
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>

          <Row className="mt-3">
            <h4
              className="mb-3"
              style={{
                borderBottom: "1px solid lightgray",
                paddingBottom: "5px",
                fontWeight: "500",
              }}
            >
              Aadhar Details
            </h4>
            <Col sm="4" className="mb-3">
              <Form.Group>
                <Form.Label>
                  Aadhar Number
                  <span style={{ color: "red" }}>*</span>
                </Form.Label>

                <Form.Control
                  name="aadharNumber"
                  value={updateStaff.aadharNumber}
                  placeholder="Enter account number"
                  onChange={handelChange}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please enter valid Aadhar Number
                </Form.Control.Feedback>
              </Form.Group>
            </Col>

            <Col sm="4" className="mb-3">
              <Form.Group>
                <Form.Label>
                  Name in Aadhar
                  <span style={{ color: "red" }}>*</span>
                </Form.Label>

                <Form.Control
                  name="aadharName"
                  value={updateStaff.aadharName}
                  placeholder="Enter account number"
                  onChange={handelChange}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please enter valid Name in Aadhar
                </Form.Control.Feedback>
              </Form.Group>
            </Col>

            <Col sm="4" className="mb-3">
              <Form.Group>
                <Form.Label>
                  Upload Aadhar Front
                  <span style={{ color: "red" }}>*</span>
                </Form.Label>

                <Form.Control
                  type="file"
                  name="aadharFrontDocs"
                  onChange={fileHandle}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please enter valid Upload PAN
                </Form.Control.Feedback>
              </Form.Group>
            </Col>

            <Col sm="4" className="mb-3">
              <Form.Group>
                <Form.Label>
                  Upload Aadhar Back
                  <span style={{ color: "red" }}>*</span>
                </Form.Label>

                <Form.Control
                  type="file"
                  name="aadharBackDocs"
                  onChange={fileHandle}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please enter valid Upload Aadhar Back
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>

          {isEmptyField && (
            <div className="mt-3" style={{ color: "red", textAlign: "right" }}>
              All mandatory fields are required.
            </div>
          )}
        </div>

        {/* STEP 4 ============================================ */}
        <div
          className="stepper-body page"
          style={{ display: activeStep == 3 ? "block" : "none" }}
        >
          {updateStaff.familyMemberList.map((row, idx) => {
            return (
              <>
                <h4
                  className="mb-3"
                  style={{ borderBottom: "1px solid lightgray" }}
                >
                  Member-{parseInt(idx) + 1}{" "}
                </h4>
                <Row>
                  <Col sm="4" className="mb-3">
                    <Form.Group>
                      <Form.Label>Name</Form.Label>
                      <Form.Control
                        name="familyMembername"
                        placeholder="Enter Name here"
                        value={row.familyMembername}
                        onChange={(e) => handleFamilyMember(idx, e)}
                      />
                    </Form.Group>
                  </Col>

                  <Col sm="4" className="mb-3">
                    <Form.Group>
                      <Form.Label>Relationship</Form.Label>
                      <Form.Select
                        name="relationship"
                        placeholder="Enter Relationship here"
                        value={row.relationship}
                        onChange={(e) => handleFamilyMember(idx, e)}
                      >
                        <option value="">Select Relationship</option>
                        {relationship.map((dv, i) => {
                          return (
                            <option
                              value={dv.value}
                              selected={
                                dv.value == row.relationship ? "selected" : ""
                              }
                              key={i}
                            >
                              {dv.label}
                            </option>
                          );
                        })}
                      </Form.Select>
                    </Form.Group>
                  </Col>

                  <Col sm={"4"} className="mb-3">
                    <Form.Group>
                      <Form.Label>DOB</Form.Label>
                      <Form.Control
                        type="date"
                        name="dob"
                        value={row.dob}
                        onChange={(e) => handleFamilyMember(idx, e)}
                      />
                    </Form.Group>
                  </Col>

                  <Col sm={"4"} className="mb-3">
                    <Form.Group>
                      <Form.Label>Gender</Form.Label>
                      <Form.Select
                        name="gender"
                        value={row.gender}
                        onChange={(e) => handleFamilyMember(idx, e)}
                      >
                        <option value="">Select Gender</option>

                        {gender.map((dv, i) => (
                          <option
                            key={i}
                            value={dv.value}
                            selected={dv.value == row.gender ? "selected" : ""}
                          >
                            {dv.label}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                  </Col>

                  <Col sm={"4"} className="mb-3">
                    <Form.Group>
                      <Form.Label>Blood Group</Form.Label>
                      <Form.Select
                        name="bloodGroup"
                        value={row.bloodGroup}
                        onChange={(e) => handleFamilyMember(idx, e)}
                      >
                        <option value="">Select Blood Group</option>
                        {bloodGroup.map((dv, i) => {
                          return (
                            <option
                              value={dv.value}
                              key={i}
                              selected={
                                dv.value == row.bloodGroup ? "selected" : ""
                              }
                            >
                              {dv.label}
                            </option>
                          );
                        })}
                      </Form.Select>
                    </Form.Group>
                  </Col>

                  <Col sm={idx == 0 ? "4" : "3"} className="mb-3">
                    <Form.Group>
                      <Form.Label>Nationality</Form.Label>
                      <Form.Select
                        name="nationality"
                        value={row.nationality}
                        onChange={(e) => handleFamilyMember(idx, e)}
                      >
                        <option value="">Select Nationality</option>
                        <option value="1">Indian</option>
                        <option value="2">Others</option>
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  {idx != 0 && (
                    <Col sm="1">
                      <div
                        onClick={() => {
                          removeFamilyMember(idx);
                        }}
                        style={{
                          marginTop: "37px",
                          color: "red",
                          cursor: "pointer",
                        }}
                      >
                        Delete
                      </div>
                    </Col>
                  )}
                </Row>
              </>
            );
          })}
          <div>
            <span
              style={{ color: "#3498db", cursor: "pointer" }}
              onClick={addMoreFamilyMember}
            >
              <FaCirclePlus /> Add more member
            </span>
          </div>
          {isEmptyField && (
            <div className="mt-3" style={{ color: "red", textAlign: "right" }}>
              All mandatory fields are required.
            </div>
          )}
        </div>

        {/* STEP 5 ============================================ */}
        <div
          className="stepper-body page"
          style={{ display: activeStep == 4 ? "block" : "none" }}
        >
          <h4 className="mb-3" style={{ borderBottom: "1px solid lightgray" }}>
            EPF Nomination
          </h4>
          {updateStaff.epf_nomination.map((row, idx) => {
            return (
              <>
                <div
                  className="mb-3"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    borderBottom: "1px solid lightgray",
                  }}
                >
                  <h4>Nominee-{parseInt(idx) + 1} </h4>
                  {idx != 0 && (
                    <div
                      onClick={() => {
                        removeNomination(idx);
                      }}
                      style={{
                        marginTop: "37px",
                        color: "red",
                        cursor: "pointer",
                      }}
                    >
                      X
                    </div>
                  )}
                </div>

                <Row>
                  <Col sm="4" className="mb-3">
                    <Form.Group>
                      <Form.Label>Name</Form.Label>
                      <Form.Select
                        name="nomineeName"
                        placeholder="Enter Name here"
                        value={row.nomineeName}
                        onChange={(e) => {
                          handleNomination(idx, e);
                        }}
                      >
                        <option value="">Select Nominee</option>
                        {data.map((row, idx) => {
                          return (
                            <option value={row.familyMembername} key={idx}>
                              {row.familyMembername}
                            </option>
                          );
                        })}
                      </Form.Select>
                    </Form.Group>
                  </Col>

                  <Col sm="4" className="mb-3">
                    <Form.Group>
                      <Form.Label>Nomination %</Form.Label>
                      <Form.Control
                        name="nominationPercent"
                        placeholder="eg: 50"
                        value={row.nominationPercent}
                        onChange={(e) => {
                          handleNomination(idx, e);
                        }}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </>
            );
          })}

          <div>
            <span
              style={{ color: "#3498db", cursor: "pointer" }}
              onClick={addMoreNomination}
            >
              <FaCirclePlus /> Add more nominee
            </span>
          </div>

          <div className="mt-5">
            <h4 style={{ borderBottom: "1px solid lightgray" }}>
              EPS Nomination
            </h4>
            <h5
              className="mb-3 mt-3"
              style={{ borderBottom: "1px solid lightgray" }}
            >
              Nominee
            </h5>
            <Row>
              <Col sm="4">
                <Form.Group>
                  <Form.Label>Name</Form.Label>
                  <Form.Select
                    name="eps_nomination"
                    placeholder="Enter Name here"
                    value={updateStaff.eps_nomination}
                    onChange={handelChange}
                  >
                    <option value="">Select Nominee</option>
                    {data.map((row, idx) => {
                      return (
                        <option value={row.familyMembername} key={idx}>
                          {row.familyMembername}
                        </option>
                      );
                    })}
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>
          </div>

          <div className="mt-5 mb-5">
            <h4 style={{ borderBottom: "1px solid lightgray" }}>
              ESI Nomination
            </h4>
            <h5
              className="mb-3 mt-3"
              style={{ borderBottom: "1px solid lightgray" }}
            >
              Nominee
            </h5>
            <Row>
              <Col sm="4">
                <Form.Group>
                  <Form.Label>Name</Form.Label>
                  <Form.Select
                    name="esi_nomination"
                    placeholder="Enter Name here"
                    value={updateStaff.esi_nomination}
                    onChange={handelChange}
                  >
                    <option value="">Select Nominee</option>
                    {data.map((row, idx) => {
                      return (
                        <option value={row.familyMembername} key={idx}>
                          {row.familyMembername}
                        </option>
                      );
                    })}
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>
          </div>

          <h4 className="mb-3" style={{ borderBottom: "1px solid lightgray" }}>
            Gratuity Nomination
          </h4>
          {updateStaff.gratuity_nomination.map((row, idx) => {
            return (
              <>
                <div
                  className="mb-3"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    borderBottom: "1px solid lightgray",
                  }}
                >
                  <h4>Nominee-{parseInt(idx) + 1} </h4>
                  {idx != 0 && (
                    <div
                      onClick={() => {
                        removeGratuityNomination(idx);
                      }}
                      style={{
                        marginTop: "37px",
                        color: "red",
                        cursor: "pointer",
                      }}
                    >
                      X
                    </div>
                  )}
                </div>

                <Row>
                  <Col sm="4" className="mb-3">
                    <Form.Group>
                      <Form.Label>Name</Form.Label>
                      {/* <Form.Control
                        name="nomineeName"
                        placeholder="Enter Name here"
                        value={row.nomineeName}
                        onChange={(e) => {
                          handleGratuityNomination(idx, e);
                        }}
                      /> */}

                      <Form.Select
                        name="nomineeName"
                        placeholder="Enter Name here"
                        value={row.nomineeName}
                        onChange={(e) => {
                          handleGratuityNomination(idx, e);
                        }}
                      >
                        <option value="">Select Nominee</option>
                        {data.map((row, idx) => {
                          return (
                            <option value={row.familyMembername} key={idx}>
                              {row.familyMembername}
                            </option>
                          );
                        })}
                      </Form.Select>
                    </Form.Group>
                  </Col>

                  <Col sm="4" className="mb-3">
                    <Form.Group>
                      <Form.Label>Nomination %</Form.Label>
                      <Form.Control
                        name="nominationPercent"
                        placeholder="eg: 50"
                        value={row.nominationPercent}
                        onChange={(e) => {
                          handleGratuityNomination(idx, e);
                        }}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </>
            );
          })}

          <div>
            <span
              style={{ color: "#3498db", cursor: "pointer" }}
              onClick={addGratuityNomination}
            >
              <FaCirclePlus /> Add more nominee
            </span>
          </div>
          {isEmptyField && (
            <div className="mt-3" style={{ color: "red", textAlign: "right" }}>
              All mandatory fields are required.
            </div>
          )}
        </div>

        {/* STEP 6 ================================================================= */}
        {/* <div
          className="stepper-body page"
          style={{ display: activeStep == 5 ? "block" : "none" }}
        >
          <h4 style={{ borderBottom: "1px solid lightgray" }}>
            Accounts & Statutory
          </h4>
          <Row>
            <Col sm="4">
              <Form.Group>
                <Form.Control
                  type="file"
                  name=""
                  id="fileInput"
                  style={{ display: "none" }}
                  onChange={attachmentFile}
                />
                <div className="file_input" onClick={selectFile}>
                  <AttachmentIcon style={{ fontSize: "18px" }} /> { attachmentFileName ? attachmentFileName : "Add Attachment" }
                </div>
              </Form.Group>
            </Col>
          </Row>
          {isEmptyField && (
            <div className="mt-3" style={{ color: "red", textAlign: "right" }}>
              All mandatory fields are required.
            </div>
          )}
        </div> */}

        <div className="stepper-footer">
          {activeStep != 0 && (
            <Button
              variant="primary"
              onClick={() => {
                handleBack("pre");
              }}
            >
              Pre
            </Button>
          )}
          {activeStep != 4 && activeStep != 3 && (
            <Button
              variant="primary"
              onClick={() => {
                handleNext("next");
              }}
            >
              Next
            </Button>
          )}
          {activeStep == 3 && (
            <Button
              variant="primary"
              onClick={() => {
                handleNext("next");
                saveFourthStepData("familyMemberList");
              }}
            >
              Next
            </Button>
          )}
          {activeStep == 4 && (
            <Button type="submit" variant="success">
              Submit
            </Button>
          )}
        </div>
      </Form>
    </div>
  ) : (
    <div className="welcom-body">
      <div class="container">
        <h1>Welcome!</h1>
        <p>
          We’re glad to have you here. Explore our content and discover what we
          have to offer. Join us and be part of our amazing community.
        </p>
        <a href="/" class="button">
          Get Started
        </a>
      </div>
    </div>
  );
};

export default Onboarding;
