const compressImage = (file, quality, maxWidth, callback) => {
  const reader = new FileReader();

  reader.readAsDataURL(file);

  reader.onload = function (event) {
    const img = new Image();
    img.src = event.target.result;

    img.onload = function () {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");

      // Calculate the new dimensions based on the maxWidth while maintaining the aspect ratio
      let width = img.width;
      let height = img.height;

      if (width > maxWidth) {
        height = Math.round((maxWidth / width) * height);
        width = maxWidth;
      }

      // Set canvas size
      canvas.width = width;
      canvas.height = height;

      // Draw the image on the canvas
      ctx.drawImage(img, 0, 0, width, height);

      // Compress the image and get the base64 data URL
      const compressedDataUrl = canvas.toDataURL("image/jpeg", quality);

      callback(compressedDataUrl); // Pass the compressed image to the callback
    };
  };

  reader.onerror = function (error) {
    console.log("Error reading file:", error);
  };

  // return newDataUrl;
};

const scrollBottom = (id) => {
  const scrollContainer = document.getElementById(id);
  if (scrollContainer) {
    scrollContainer.scrollTo({
      top: scrollContainer.scrollHeight,
      left: 0,
    });
  }
};

const getDayName = (day) => {
  var days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

  return days[day];
};

const getMonthName = (month) => {
  var days = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  return days[month];
};

const getDaysInMonth = (month, year) => {
  // Get the number of days in the given month
  let numDays = new Date(year, month + 1, 0).getDate();
  let numMonth = new Date(year, month + 1, 0).getMonth();

  const cMon = getMonthName(numMonth);

  // Use Array.from() to create an array of dates
  return Array.from({ length: numDays }, (_, i) => {
    const m = new Date(year, month, i + 1);
    return {
      month: cMon,
      date: m.getDate(),
      day: getDayName(m.getDay()),
    };
  });
};

const dropdownFunc = () => {
  const selectedAll = document.querySelectorAll(".wrapper_dropdown");

  selectedAll.forEach((selected) => {
    const optionsList = selected.querySelectorAll("div.wrapper_dropdown li");

    selected.addEventListener("click", () => {
      let arrow = selected.children[1];

      let classList = selected.classList;

      if (classList.contains("active")) {
        handleDropdown(selected, arrow, false);
      } else {
        let currentActive = document.querySelector(".wrapper_dropdown.active");

        if (currentActive) {
          let anotherArrow = currentActive.children[1];
          handleDropdown(currentActive, anotherArrow, false);
        }

        handleDropdown(selected, arrow, true);
      }
    });

    // update the display of the dropdown
    for (let o of optionsList) {
      o.addEventListener("click", () => {
        selected.querySelector(".selected-display").innerHTML = o.innerHTML;
      });
    }
  });

  // check if anything else ofther than the dropdown is clicked
  window.addEventListener("click", function (e) {
    if (e.target.closest(".wrapper_dropdown") === null) {
      closeAllDropdowns();
    }
  });

  // close all the dropdowns
  function closeAllDropdowns() {
    const selectedAll = document.querySelectorAll(".wrapper_dropdown");
    selectedAll.forEach((selected) => {
      const optionsContainer = selected.children[2];
      let arrow = selected.children[1];

      handleDropdown(selected, arrow, false);
    });
  }

  // open all the dropdowns
  function handleDropdown(dropdown, arrow, open) {
    if (open) {
      arrow.classList.add("rotated");
      dropdown.classList.add("active");
    } else {
      arrow.classList.remove("rotated");
      dropdown.classList.remove("active");
    }
  }
};

const checkLength = (e) => {
  if (e.target.value.length === e.target.maxLength) {
    e.stopPropagation();
    e.preventDefault();
    return false;
  }
  return true;
};

const convertPDFToBase64 = (pdfFile) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = () => {
      // Convert the file to a Base64 string
      const base64String = reader.result.split(",")[1]; // Get Base64 part after the data URL prefix
      resolve("data:application/pdf;base64," + base64String);
    };

    reader.onerror = (error) => {
      reject(error);
    };

    // Read the PDF file as a Data URL
    reader.readAsDataURL(pdfFile);
  });
};

const customStyles = {
  headCells: {
    style: {
      borderBottom: "2px solid #000", // Add a bottom border to header cells
      fontWeight: "bold", // Optional: make header text bold
    },
  },
};

const ISOTimeFormat = (time) => {
  const date = new Date(time);

  // Get individual components in India Standard Time (GMT+0530)
  const options = {
    timeZone: "Asia/Kolkata",
    weekday: "short",
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    hour12: false,
  };

  // Format each part in India Standard Time
  const formattedDate = date.toLocaleString("en-US", options).replace(",", "");

  // Get the GMT offset
  const timeZoneName = date
    .toLocaleTimeString("en-US", {
      timeZone: "Asia/Kolkata",
      timeZoneName: "shortOffset",
    })
    .split(" ")[2];
  const formattedTime = `${formattedDate} ${timeZoneName} (India Standard Time)`;
  return formattedTime;
};

const getFileExtension = (url) => {
  const lastDotIndex = url.lastIndexOf(".");
  if (lastDotIndex !== -1) {
    return url.substring(lastDotIndex);
  }
  return null; // Return null if no extension is found
};

export {
  compressImage,
  scrollBottom,
  getDaysInMonth,
  getDayName,
  dropdownFunc,
  checkLength,
  convertPDFToBase64,
  customStyles,
  ISOTimeFormat,
  getFileExtension,
};
