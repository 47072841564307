import { EmbedPDF } from "@simplepdf/react-embed-pdf";

const PDFreader = ({ file }) => {
  return (
    <EmbedPDF
      mode="inline"
      style={{ width: "100%", height: 800 }}
      documentURL={file}
    />
  );
};

export default PDFreader;
