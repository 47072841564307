import "./Holiday.css";
import { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import TableMui from "../../../components/table/TableMui";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import { Form, Row, Col } from "react-bootstrap";
import EditNoteIcon from "@mui/icons-material/EditNote";
import DeleteIcon from "@mui/icons-material/Delete";
import ButtonCom from "../../../components/button/button";

import { CiEdit } from "react-icons/ci";
import { MdOutlineDeleteSweep } from "react-icons/md";
import Modal from "react-bootstrap/Modal";
import { ToastContainer, toast } from "react-toastify";
import DataTable from "react-data-table-component";

import { useDispatch, useSelector } from "react-redux";
import { fetchData, saveHoliday } from "../../../redux/slices/holidaySlice";
import {
  commonSaveData,
  commonGetData,
  initState,
  initCommonState,
} from "../../../redux/slices/commonSlice";

const Holiday = () => {
  const dispatch = useDispatch();
  const holidayState = useSelector((holidayState) => holidayState.holiday);
  const state = useSelector((state) => state.common);

  const user = localStorage.getItem("user_data")
    ? JSON.parse(localStorage.getItem("user_data"))
    : null;

  const [open, setOpen] = useState(false);
  const [validated, setValidated] = useState(false);
  const [holidayList, setHolidayList] = useState([]);
  const [holiday, setHoliday] = useState({
    userId: user.user_id,
    holidayId: null,
    holiday_name: "",
    holiday_date: "",
  });

  const getHolidayList = useCallback(() => {
    dispatch(fetchData( user.user_id));
  }, []);

  useEffect(() => {
    getHolidayList();
  }, []);

  useEffect(() => {
    if (holidayState.holyGetSuccess) {
      console.log(holidayState.holyGetData)
      setHolidayList(holidayState.holyGetData);
    }
  }, [holidayState.holyGetSuccess, holidayState.holyGetError]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setHoliday({
      ...holiday,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    const form = e.currentTarget;
    e.preventDefault();
    e.stopPropagation();
    if (form.checkValidity() === false) {
      setValidated(true);
    } else {
      dispatch(saveHoliday(holiday));
    }
  };

  useEffect(() => {
    if(holidayState.holySuccess){
      toast.success(holidayState.holyMessage);
      setOpen(false);
    }
    if(holidayState.holyError){
      toast.success(holidayState.holyMessage);
      setOpen(false);
    }
  }, [holidayState.holySuccess, holidayState.holyError])

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setHoliday({
      ...holiday,
      holiday_name: "",
      holiday_date: "",
    });
    setValidated(false);
    setOpen(false);
  };

  // Edit functions
  const [show, setShow] = useState(false);

  const handleModalClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [isEdit, setIsEdit] = useState(false);

  const handleEdit = (row) => {
    setIsEdit(true);
    setHoliday({
      ...holiday,
      holidayId: row.holidayId,
      holiday_name: row.holiday_name,
      holiday_date: row.holiday_date,
    });
    handleClickOpen();
  };

  const handleEditSubmit = (e) => {
    e.preventDefault();
    let d = {
      path: "admin/updateMyHoliday",
      data: holiday,
    };
    dispatch(commonSaveData(d));
  };

  // Delete functions
  const [delId, setDelId] = useState(null);
  const handleDelete = (row) => {
    setDelId(row.holidayId);
    handleShow();
  };
  const handleDeleteSubmit = () => {
    let d = {
      path: "admin/deleteMyHoliday",
      data: {
        userId: user?.user_id,
        holidayId: delId,
      },
    };
    dispatch(commonSaveData(d));
    handleModalClose();
  };

  useEffect(() => {
    if (state.commonSuccess) {
      toast.success(state.commonMessage);
      setOpen(false);
      getHolidayList();
      setIsEdit(false);
      dispatch(initCommonState());
    }
    if (state.commonError) {
      toast.error(state.commonMessage);
      setOpen(false);
      getHolidayList();
      dispatch(initCommonState());
    }
  }, [state.commonSuccess, state.commonError]);

  const columns = [
    {
      name: "Holiday Name",
      selector: (row) => row.holiday_name,
    },
    {
      name: "Date",
      selector: (row) => row.holiday_date,
    },

    {
      name: "Status",
      selector: (row) => {
        return row.status == 1 ? "Active" : "InActive";
      },
    },
    {
      name: "Actions",
      cell: (row) => {
        return (
          <div>
            <CiEdit
              style={{
                cursor: "pointer",
                fontSize: 30,
                color: "blue",
                marginRight: "10px",
              }}
              onClick={() => {
                handleEdit(row);
              }}
            />
            <MdOutlineDeleteSweep
              style={{ cursor: "pointer", fontSize: 30, color: "red" }}
              onClick={() => handleDelete(row)}
            />
          </div>
        );
      },
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      width: "200px", // Set a width for the action buttons
    },
  ];
  const customStyles = {
    table: {
      style: {
        textAlign: "center", // Center-align header text
        justifyContent: "center", // Additional property to center content horizontally
        display: "flex", // Border around the entire table
      },
    },
    rows: {
      style: {
        textAlign: "center", // Center-align header text
        justifyContent: "center", // Additional property to center content horizontally
        display: "flex",
      },
    },
    cells: {
      style: {
        textAlign: "center", // Center-align header text
        justifyContent: "center", // Additional property to center content horizontally
        display: "flex",
      },
    },
    header: {
      style: {
        textAlign: "center", // Center-align header text
        justifyContent: "center", // Additional property to center content horizontally
        display: "flex",
      },
    },
    headCells: {
      style: {
        textAlign: "center", // Center-align header text
        justifyContent: "center", // Additional property to center content horizontally
        display: "flex",
        borderRight: "1px solid lightgray",
      },
    },
  };
  return (
    <div className="SettingBody">
      <Modal show={show} onHide={handleModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>Delete Break</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure! to delete this record?</Modal.Body>
        <Modal.Footer>
          <Button
            style={{
              backgroundColor: "gray",
              color: "#fff",
              marginRight: "5px",
            }}
            onClick={handleModalClose}
          >
            Cancel
          </Button>
          <Button
            style={{ backgroundColor: "red", color: "#fff" }}
            onClick={handleDeleteSubmit}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        className="toast-custom"
      />

      <div className="SettingBody-head">
        <Link to={"/setting/"}>
          <ArrowBackIcon sx={{ scale: 1 }} />
        </Link>
        <p>Holiday</p>
      </div>

      <div className="SettingBody-card mt-3">
        <div className="Branches-body" style={{ borderRadius: "7px" }}>
          <div className="Branches-table">
            <div className="Holiday-table-head">
              <div
                className="Holiday-table-title"
                style={{ backgroundColor: "red" }}
              >
                {/* <h2>Holiday List</h2> */}
                <input
                  type="month"
                  style={{ padding: "8px 10px", width: "100%" }}
                />
              </div>
              <div className="Holiday-table-btn mb-3">
                <button id="Add-Holiday-btn">Add All Holiday</button>
                <ButtonCom
                  type={"button"}
                  class_name={"p_btn"}
                  text={"Add New Holidays"}
                  ext
                  url_or_onclick={handleClickOpen}
                  icon={<AddIcon />}
                  width={""}
                />
              </div>
            </div>
            <DataTable
              columns={columns}
              data={holidayList}
              pagination
              subHeader
              customStyles={customStyles}
            />
          </div>
        </div>
      </div>

      <Dialog
        fullWidth={true}
        maxWidth={"sm"}
        open={open}
        onClose={handleClose}
      >
        <DialogTitle>
          <h3>Add New Branch</h3>
        </DialogTitle>
        <Form
          noValidate
          validated={validated}
          onSubmit={isEdit ? handleEditSubmit : handleSubmit}
        >
          <DialogContent>
            <Row>
              <Col sm="12">
                <Form.Group className="mb-2">
                  <Form.Label>Holiday Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="holiday_name"
                    value={holiday.holiday_name}
                    placeholder="Enter Holiday Name"
                    onChange={handleChange}
                    required
                  />
                  <Form.Control.Feedback type={"invalid"}>
                    Please provide valid holiday name.
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              {/* ---------------------------- */}
              <Col sm="12">
                <Form.Group>
                  <Form.Label>Holiday Date</Form.Label>
                  <Form.Control
                    type="date"
                    name="holiday_date"
                    // value="2024-12-02"
                    defaultValue={holiday.holiday_date}
                    onChange={handleChange}
                    required
                  />
                  <Form.Control.Feedback type={"invalid"}>
                    Please provide valid holiday date.
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              color="gray"
              onClick={handleClose}
              style={{
                fontWeight: "bold",
                fontSize: "16px",
                lineHeight: "30px",
                backgroundColor: "gray",
                color: "white",
              }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="info"
              type="submit"
              style={{
                fontWeight: "bold",
                fontSize: "16px",
                lineHeight: "30px",
              }}
            >
              Save
            </Button>
          </DialogActions>
        </Form>
      </Dialog>
    </div>
  );
};

export default Holiday;
