import "./SettingBody.css";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import Avatar from "@mui/material/Avatar";
// Icons
import { TbSettingsPlus } from "react-icons/tb";
import AccountTreeOutlinedIcon from "@mui/icons-material/AccountTreeOutlined";
import SummarizeOutlinedIcon from "@mui/icons-material/SummarizeOutlined";
import PermIdentityOutlinedIcon from "@mui/icons-material/PermIdentityOutlined";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import Switch from "@mui/material/Switch";
import AvTimerOutlinedIcon from "@mui/icons-material/AvTimerOutlined";
import CoffeeOutlinedIcon from "@mui/icons-material/CoffeeOutlined";
import FingerprintOutlinedIcon from "@mui/icons-material/FingerprintOutlined";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import BeachAccessOutlinedIcon from "@mui/icons-material/BeachAccessOutlined";
import FmdGoodOutlinedIcon from "@mui/icons-material/FmdGoodOutlined";
import CenterFocusWeakOutlinedIcon from "@mui/icons-material/CenterFocusWeakOutlined";
import FileCopyOutlinedIcon from "@mui/icons-material/FileCopyOutlined";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import LoginOutlinedIcon from "@mui/icons-material/LoginOutlined";
import { Link } from "react-router-dom";
// Redux -----------------------------------------------------------------------
import { useDispatch, useSelector } from "react-redux";
import { commonGetData } from "../../redux/slices/commonSlice";
import { useEffect, useCallback, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { dropdownFunc } from "../../services/commonServices";
import ButtonCom from "../../components/button/button";

const SettingBody = () => {
  return (
    <div className="SettingBody">
      {/* <div className="SettingBody-head">
        <Link to={"/dashboard"}>
          <ArrowBackIcon sx={{ scale: 1 }} />
        </Link>
        <p>Setting</p>
      </div> */}

      <div className="SettingBody-card">
        <CompanyDetails />
        <MyTeam />
        <AttendanceSettings />
        <SalarySetting />
        <AlertNotifications />
        <OtherSettings />
      </div>
    </div>
  );
};

const CompanyDetails = () => {
  const [user, setUser] = useState(
    localStorage.getItem("user_data")
      ? JSON.parse(localStorage.getItem("user_data"))
      : []
  );
  return (
    <>
      <SettingBodyCard title={"CompanyDetails"}>
        <SettingCard
          icon={<Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" />}
          heading={user.company_name}
          subHeading={"Edit Company Details"}
          routPath={"/company-details"}
        />
        <SettingCard
          icon={<AccountTreeOutlinedIcon sx={{ scale: 1.1 }} />}
          heading={"My Branches"}
          subHeading={"Add or remove branches"}
          routPath={"/company_branches"}
        />

        <SettingCard
          icon={<AccountTreeOutlinedIcon sx={{ scale: 1.1 }} />}
          heading={"My Departments"}
          subHeading={"Add or remove Departments"}
          routPath={"/company-departments"}
        />

        <SettingCard
          icon={<AccountTreeOutlinedIcon sx={{ scale: 1.1 }} />}
          heading={"My Designation"}
          subHeading={""}
          routPath={"/company-designation"}
        />

        <SettingCard
          icon={<AccountTreeOutlinedIcon sx={{ scale: 1.1 }} />}
          heading={"My Roles"}
          subHeading={""}
          routPath={"/company-Roles"}
        />

        <SettingCard
          icon={<AccountTreeOutlinedIcon sx={{ scale: 1.1 }} />}
          heading={"My Employee Id"}
          subHeading={""}
          routPath={"/my-employee-id"}
        />

        <SettingCard
          icon={<SummarizeOutlinedIcon sx={{ scale: 1 }} />}
          heading={"My Company Reports"}
          subHeading={" Generate Different types of report for whole Company"}
          routPath={"/company-reports"}
        />
      </SettingBodyCard>
    </>
  );
};

const MyTeam = () => {
  return (
    <>
      <SettingBodyCard title={"My Team"}>
        <SettingCard
          icon={<PeopleAltOutlinedIcon sx={{ scale: 1.1 }} />}
          heading={"Admins"}
          subHeading={"Add more Admins to your company"}
          routPath={"/myteam-admins"}
        />

        <SettingCard
          icon={<PeopleAltOutlinedIcon sx={{ scale: 1.1 }} />}
          heading={"Employees & Managers"}
          subHeading={"Managers your staff"}
          routPath={"/employee-manager"}
        />

        <SettingCard
          icon={<SummarizeOutlinedIcon sx={{ scale: 1.1 }} />}
          heading={"Custom Fields"}
          subHeading={"Create Custom staff information fields"}
          routPath={""}
        />
      </SettingBodyCard>

      {/* <div className="setting-cards">
          <div className="setting-cards-title">
            <p>My Team</p>
          </div>
          <Link to={"myteam-admins"}>
            <div className="setting-cards-Links">
              <div className="link-info">
                <p>
                  <PeopleAltOutlinedIcon sx={{ scale: 1.5 }} />
                </p>
                <div className="link-info-title">
                  <p>Admins</p>
                  <label>Add more Admins to your company</label>
                </div>
              </div>

              <ArrowForwardIcon sx={{ scale: 1.5 }} />
            </div>
          </Link>

          <Link to={""}>
            <div className="setting-cards-Links">
              <div className="link-info">
                <p>
                  <PermIdentityOutlinedIcon sx={{ scale: 1.5 }} />
                </p>

                <div className="link-info-title">
                  <p>Employees & Managers</p>
                  <label>Managers your staff</label>
                </div>
              </div>

              <ArrowForwardIcon sx={{ scale: 1.5 }} />
            </div>
          </Link>

          <Link to={""}>
            <div className="setting-cards-Links">
              <div className="link-info">
                <p>
                  <SummarizeOutlinedIcon sx={{ scale: 1.5 }} />
                </p>
                <div className="link-info-title">
                  <p>Custom Fields</p>
                  <label>Create Custom staff information fields</label>
                </div>
              </div>

              <ArrowForwardIcon sx={{ scale: 1.5 }} />
            </div>
          </Link>
        </div> */}
    </>
  );
};

const AttendanceSettings = () => {
  return (
    <>
      <SettingBodyCard title={"Attendance Settings"}>
        <SettingCard
          icon={<AvTimerOutlinedIcon sx={{ scale: 1.1 }} />}
          heading={"Shifts"}
          subHeading={"Add or remove company shifts"}
          routPath={"/attendance-settings-shifts"}
        />

        <SettingCard
          icon={<CoffeeOutlinedIcon sx={{ scale: 1.1 }} />}
          heading={"Breaks"}
          subHeading={"Add or remove company breaks"}
          routPath={"/breaks"}
        />

        <SettingCard
          icon={<CalendarMonthOutlinedIcon sx={{ scale: 1.1 }} />}
          heading={"Holiday List"}
          subHeading={"Add remove public holiday for your company"}
          routPath={"/attendance-settings-holiday"}
        />

        <SettingCard
          icon={<CalendarMonthOutlinedIcon sx={{ scale: 1.1 }} />}
          heading={"Leave Settings"}
          subHeading={"Create custom company leaves"}
          routPath={"/leave-attendance"}
        />

        <SettingCard
          icon={
            <TbSettingsPlus
              style={{ width: "25px", height: "25px" }}
              sx={{ scale: 1 }}
            />
          }
          heading={"Attendance Settings"}
          subHeading={"You have more attendance settings here"}
          routPath={"/attendance-setting"}
        />
      </SettingBodyCard>
    </>
  );
};

const SalarySetting = () => {
  const dispatch = useDispatch();
  const commonState = useSelector((state) => state.common);
  const [calendarMonthList, setCalendarMonthList] = useState([]);

  const getAllowance = useCallback(() => {
    dispatch(commonGetData("admin/getCalendarMonthList"));
  }, []);

  useEffect(() => {
    getAllowance();
  }, []);

  useEffect(() => {
    if (commonState.commonGetSuccess) {
      console.log(commonState.commonGetData);
      setCalendarMonthList(commonState.commonGetData);
    }
  }, [commonState.commonGetSuccess]);

  // dropdown---------------------------------------------------------
  const handleDropdown = (dropdown, arrow, open) => {
    if (open) {
      arrow.classList.add("rotated");
      dropdown.classList.add("active");
    } else {
      arrow.classList.remove("rotated");
      dropdown.classList.remove("active");
    }
  };

  const openDropdown = () => {};

  // const dp = useCallback(() => {
  //   const selectedAll = document.querySelectorAll(".wrapper_dropdown");

  //   selectedAll.forEach((selected) => {
  //     selected.addEventListener("click", () => {
  //       let arrow = selected.children[1];
  //       let classList = selected.classList;

  //       if (classList.contains("active")) {
  //         handleDropdown(selected, arrow, false);
  //       } else {
  //         let currentActive = document.querySelector(
  //           ".wrapper_dropdown.active"
  //         );

  //         if (currentActive) {
  //           let anotherArrow = currentActive.children[1];
  //           handleDropdown(currentActive, anotherArrow, false);
  //         }

  //         handleDropdown(selected, arrow, true);
  //       }
  //     });
  //   });

  //   window.addEventListener("click", function (e) {
  //     if (e.target.closest(".wrapper-dropdown") === null) {
  //       closeAllDropdowns();
  //     }
  //   });
  // }, []);

  useEffect(() => {
    // dp();
    dropdownFunc();
  }, []);

  const handleCalendarMonthList = (id, name) => {
    document.querySelector(".selected-display").innerHTML = name;

    // const selectedAll = document.querySelectorAll(".wrapper_dropdown");
    // selectedAll.forEach((selected) => {
    //   const optionsContainer = selected.children[2];
    //   let arrow = selected.children[1];

    //   handleDropdown(selected, arrow, false);
    // });
  };

  const handleStartDate = (e) => {
    let { name, value } = e.target;
    if (value == 1) {
      document.querySelector(".start-date").value = 31;
    } else {
      document.querySelector(".start-date").value = value - 1;
    }
  };

  return (
    <>
      <div className="salary_settings">
        <p className="mb-4" style={{ fontSize: "20px", fontWeight: "500" }}>
          Salary Setting
        </p>

        <Row style={{ padding: "0px 10px" }}>
          <Col
            sm="6"
            style={{
              padding: "20px 10px",
              borderRadius: "10px",
              backgroundColor: "#fff",
            }}
          >
            <div className="attendance-settings-left">
              <div
                className="inner-div"
                style={{ borderBottom: "1px dotted lightgray" }}
              >
                <Form>
                  <Row>
                    <Col sm="3">
                      <label style={{ marginTop: "8px", whiteSpace: "nowrap" }}>
                        Calendar Month
                      </label>
                    </Col>
                    <Col sm="6" style={{ paddingRight: 0 }}>
                      <div
                        className="wrapper_dropdown"
                        id="dropdown"
                        onClick={openDropdown}
                      >
                        <span
                          className="selected-display"
                          id="destination"
                          style={{ color: "gray" }}
                        >
                          Select calendar month
                        </span>
                        <svg
                          id="drp-arrow"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          className="arrow transition-all ml-auto rotate-180"
                        >
                          <path
                            d="M7 14.5l5-5 5 5"
                            stroke="currentColor"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></path>
                        </svg>

                        <ul className="dropdown">
                          {Array.isArray(calendarMonthList) &&
                            calendarMonthList.map((row, idx) => {
                              return (
                                <li
                                  className="item"
                                  key={idx}
                                  style={{
                                    borderBottom: "1px solid lightgray",
                                  }}
                                  onClick={() =>
                                    handleCalendarMonthList(
                                      row.calendarId,
                                      row.month_name
                                    )
                                  }
                                >
                                  <div>{row.month_name} </div>
                                  <div
                                    style={{
                                      marginTop: "-30px",
                                      fontSize: "12px",
                                      color: "gray",
                                    }}
                                  >
                                    {row.month_subtext}
                                  </div>
                                </li>
                              );
                            })}
                        </ul>
                      </div>
                    </Col>
                    <Col sm="3" className="right-left-align">
                      <ButtonCom
                        type={"button"}
                        class_name={"p_btn"}
                        text="Update"
                        url_or_onclick={() => {}}
                        icon={null}
                        width={""}
                      />
                    </Col>
                  </Row>
                </Form>
              </div>
            </div>

            <div className="attendance-settings-left">
              <div className="inner-div">
                <Row>
                  <Col sm="3">
                    <label style={{ whiteSpace: "nowrap" }}>
                      Attendance Cycles
                    </label>
                  </Col>
                  <Col sm="6" style={{ paddingRight: 0, color: "gray" }}>
                    <select className="option_dropdown">
                      <option value="option1"> 1</option>
                      <option value="option2"> 2</option>
                      <option value="option3"> 3</option>
                    </select>
                  </Col>
                  <Col sm="3" className="right-left-align">
                    <ButtonCom
                      type="button"
                      class_name={"p_btn"}
                      text="Add +"
                      icon={null}
                      width="95px"
                    />
                  </Col>
                </Row>
              </div>
            </div>

            <div className="attendance-settings-left">
              <div
                className="inner-div"
                style={{ borderBottom: "1px dotted lightgray" }}
              >
                <Form>
                  <Row>
                    <Col sm="3">
                      <label style={{ whiteSpace: "nowrap" }}>Start Date</label>
                    </Col>
                    <Col
                      sm="6"
                      style={{ paddingRight: 0 }}
                      className="flex-justify-center align-items-center"
                    >
                      {(() => {
                        const rows = [];
                        for (let i = 1; i < 32; i++) {
                          // note: we are adding a key prop here to allow react to uniquely identify each
                          // element in this array. see: https://reactjs.org/docs/lists-and-keys.html
                          rows.push(
                            <option value={i} key={i}>
                              {" "}
                              {i}
                            </option>
                          );
                        }

                        return (
                          <Form.Select
                            id="width-70-px"
                            onChange={handleStartDate}
                            style={{ color: "gray" }}
                          >
                            {rows}
                          </Form.Select>
                        );
                      })()}

                      <p> to</p>

                      <Form.Control
                        placeholder="0"
                        id="width-70-px"
                        className="start-date"
                        value={31}
                        readOnly
                      />
                    </Col>
                    <Col sm="3" className="right-left-align">
                      <ButtonCom
                        type="button"
                        class_name={"p_btn"}
                        text="Update"
                        url_or_onclick={() => {}}
                        icon={null}
                        width={""}
                      />
                    </Col>
                  </Row>
                </Form>
              </div>
            </div>

            <div className="attendance-settings-left">
              <div
                className="inner-div"
                style={{ borderBottom: "1px dotted lightgray" }}
              >
                <Form>
                  <Row>
                    <Col sm="3">
                      <Form.Label>Regularization Period</Form.Label>
                    </Col>
                    <Col sm="6" style={{ paddingRight: 0 }}>
                      <select
                        className="option_dropdown"
                        name="regularization"
                        style={{ color: "gray" }}
                      >
                        <option value="1"> 1 day</option>
                        <option value="2"> 2 days</option>
                      </select>
                    </Col>
                    <Col sm="3"></Col>
                  </Row>
                </Form>
              </div>
            </div>

            <div className="attendance-settings-left">
              <div className="inner-div">
                <Form>
                  <Row>
                    <Col sm="3">
                      <Form.Label>Probation Leave Rules</Form.Label>
                    </Col>
                    <Col sm="6" style={{ paddingRight: 0 }}>
                      <Form.Control placeholder="Enter probation rules" />
                    </Col>
                    <Col sm="3" className="right-left-align">
                      <ButtonCom
                        type="button"
                        class_name={"p_btn"}
                        text="Update"
                        url_or_onclick={() => {}}
                        icon={null}
                        width={""}
                      />
                    </Col>
                  </Row>
                </Form>
              </div>
            </div>
          </Col>

          <Col sm="6">
            <div className="attendance-settings-right">
              <Row>
                <Col sm="12" className="mb-3">
                  <SettingCardOne
                    icon={<FileCopyOutlinedIcon sx={{ scale: 1.1 }} />}
                    heading={"Salary Details Import Settings"}
                    subHeading={"Configure setting for Salary Date import"}
                    routPath={"/salary-details"}
                    style={{ width: "100%" }}
                  />
                </Col>
                <Col sm="12">
                  <SettingCardOne
                    icon={<FileCopyOutlinedIcon sx={{ scale: 1.1 }} />}
                    heading={"Create Salary Template"}
                    subHeading={
                      "Define templates for splitting Employee CTC into different earning heads"
                    }
                    routPath={"/salary-template"}
                  />
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

const AlertNotifications = () => {
  return (
    <>
      <SettingBodyCard title={"Alert Notifications"}>
        <SettingCard
          icon={<NotificationsOutlinedIcon sx={{ scale: 1.1 }} />}
          heading={"Alert Notifications"}
          subHeading={"Get important alerts on App"}
          routPath={""}
          switchBtn={
            <Switch
              //   checked={checked}
              //   onChange={handleChange}
              inputProps={{ "aria-label": "controlled" }}
            />
          }
        />
      </SettingBodyCard>
      {/* <div className="setting-cards">
        <div className="setting-cards-title">
          <p>Alert Notifications</p>
        </div>

        <div className="setting-cards-Links">
          <div className="link-info">
            <p>
              <NotificationsOutlinedIcon sx={{ scale: 1.5 }} />
            </p>
            <div className="link-info-title">
              <p>App Notifications</p>
              <label>Get important alerts on App </label>
            </div>
          </div>

          <Switch
            //   checked={checked}
            //   onChange={handleChange}
            inputProps={{ "aria-label": "controlled" }}
          />
        </div>
      </div> */}
    </>
  );
};

const OtherSettings = () => {
  const logOut = () => {
    localStorage.removeItem("auth");
    localStorage.removeItem("superadmin");
    window.location.reload();
  };
  return (
    <>
      <SettingBodyCard title={"Other Settings"}>
        <SettingCard
          icon={<PeopleAltOutlinedIcon sx={{ scale: 1.1 }} />}
          heading={"Request A Feature"}
          subHeading={"Give your valuable feedback and feather request"}
          routPath={""}
          switchBtn={<Switch inputProps={{ "aria-label": "controlled" }} />}
        />
        <SettingCard
          icon={<LoginOutlinedIcon sx={{ scale: 1.1 }} />}
          heading={"Logout"}
          subHeading={"Logout from devices"}
          routPath={null}
          switchBtn={null}
          onClick={logOut}
        />
      </SettingBodyCard>

      {/* <div className="setting-cards">
        <div className="setting-cards-title">
          <p>Other Settings</p>
        </div>
        <Link to={""}>
          <div className="setting-cards-Links">
            <div className="link-info">
              <p>
                <PeopleAltOutlinedIcon sx={{ scale: 1.5 }} />
              </p>
              <div className="link-info-title">
                <p>Request A Feature</p>
                <label>Give your valuable feedback and feather request</label>
              </div>
            </div>

            <ArrowForwardIcon sx={{ scale: 1.5 }} />
          </div>
        </Link>

        <div className="setting-cards-Links" onClick={logOut}>
          <div className="link-info">
            <p>
              <LoginOutlinedIcon sx={{ scale: 1.5 }} />
            </p>

            <div className="link-info-title">
              <p>Logout</p>
              <label>Logout from devices</label>
            </div>
          </div>

          <ArrowForwardIcon sx={{ scale: 1.5 }} />
        </div>
      </div> */}
    </>
  );
};

const SettingCard = ({
  icon,
  heading,
  subHeading,
  routPath,
  switchBtn,
  onClick,
}) => {
  return (
    <div
      className="SettingCard"
      onClick={onClick}
      style={{ cursor: heading == "Logout" ? "pointer" : "" }}
    >
      <div className="SettingCard-item">
        <div className="SettingCard-icon">{icon}</div>
        <div className="SettingCard-body">
          <p>{heading}</p>
          <label>{subHeading}</label>
        </div>
        <div>{switchBtn || ""}</div>
      </div>
      {!switchBtn && switchBtn !== null && (
        <div className="SettingCard-Button">
          {switchBtn || onClick ? (
            <></>
          ) : (
            <Link to={routPath}>
              <ArrowForwardIcon className="btn-arrow" />
            </Link>
          )}
        </div>
      )}
    </div>
  );
};

const SettingCardOne = ({
  icon,
  heading,
  subHeading,
  routPath,
  switchBtn,
  onClick,
}) => {
  return (
    <div
      className="SettingCard"
      onClick={onClick}
      style={{ cursor: heading == "Logout" ? "pointer" : "", width: "100%" }}
    >
      <div className="SettingCard-item">
        <div className="SettingCard-icon">{icon}</div>
        <div className="SettingCard-body">
          <p>{heading}</p>
          <label>{subHeading}</label>
        </div>
        <div>{switchBtn || ""}</div>
      </div>
      {!switchBtn && switchBtn !== null && (
        <div className="SettingCard-Button">
          {switchBtn || onClick ? (
            <></>
          ) : (
            <Link to={routPath}>
              <ArrowForwardIcon className="btn-arrow" />
            </Link>
          )}
        </div>
      )}
    </div>
  );
};

const SettingBodyCard = ({ title, children }) => {
  return (
    <div className="SettingTitle-body">
      <p>{title}</p>
      <div className="Setting-body-content">{children}</div>
    </div>
  );
};

export default SettingBody;
